import type { StoreMenuItem } from '@/root/../../packages/ui-lib/src';
import { useEffect, useState } from 'react';
import { useUndoState } from 'rooks';
import { useContextSelector } from 'use-context-selector';
import { GlobalContentContext } from 'components/globalContentContext/GlobalContentContext';
import { MenuContext } from 'components/menuContext/MenuContext';
import { MenuDraw } from '../menuDraw/MenuDraw';
import styles from './MenuDrawerCover.module.scss';

export const MenuDrawerCover = () => {
  const [menuArray, setMenuArray, undoMenuArray] = useUndoState<
    Array<StoreMenuItem>
  >([]);
  const [menuArrayPostAnimation, setMenuArrayPostAnimation] = useState<
    Array<StoreMenuItem>
  >([]);
  const [activeMenu, setActiveMenu, undoActiveMenu] =
    useUndoState<StoreMenuItem>({ id: 'menuRoot', title: '' });
  const mobileMenu: StoreMenuItem[] =
    useContextSelector(MenuContext, ({ menus }) => menus?.mobile) || [];
  const socialLinks = useContextSelector(
    GlobalContentContext,
    ({ socialLinks }) => socialLinks
  );

  useEffect(() => {
    if (!menuArray.includes(activeMenu)) {
      setMenuArray([...menuArray, activeMenu]);
    }

    if (menuArray.length < menuArrayPostAnimation.length) {
      setTimeout(() => {
        setMenuArrayPostAnimation(menuArray);
      }, 300);
    } else {
      setMenuArrayPostAnimation(menuArray);
    }
  }, [activeMenu, menuArray]);

  return (
    <div
      className={styles.menuDrawerCover}
      style={{
        transform: `translateX(-${(menuArray.length - 1) * 90}vw)`,
        width: `${90 * menuArrayPostAnimation.length}vw`,
      }}
    >
      {menuArrayPostAnimation.map((activeMenu, index) => {
        return (
          <MenuDraw
            key={index}
            {...{
              mobileMenu,
              socialLinks,
              activeMenu,
              setActiveMenu,
              undoActiveMenu,
              undoMenuArray,
              activeTierIndex: index,
              menuArray,
              menuArrayPostAnimation,
            }}
          />
        );
      })}
    </div>
  );
};
