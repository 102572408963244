import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { SocialLinks } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, forwardRef } from 'react';
import {
  hasLocaleSupportForProUsers,
  isChLocale,
  storeLocale,
  WELLASTORE_PROFESSIONAL_URL,
} from '@store-constants';
import type { SocialLink } from 'components/globalContentContext/GlobalContentContext';
import { LocaleSwitcherContainer } from 'components/localeSwitcher/LocaleSwitcher';
import type {
  StoreMenuItem,
  MediaBlockType,
} from 'components/menuContext/MenuContext';
import type { ImageType } from 'components/plpContext/types/storePLP';
import IconChevronLeft from 'icons/direction/chevron/chevron-left.svg';
import IconChevronRight from 'icons/direction/chevron/chevron-right.svg';
import IconAccount from 'icons/misc/account.svg';
import IconHeart from 'icons/misc/heart.svg';
import IconLocation from 'icons/misc/location.svg';
import IconPhone from 'icons/misc/phone.svg';
import IconProLogin from 'icons/misc/pro-login.svg';

import { useGetSalonFinderRoute } from 'utils/hooks/useGetSalonFinderRoute';
import styles from './MenuDraw.module.scss';

/**
 * MenuDraw component.
 * @param props - MenuDraw component props.
 */
export const MenuDraw = ({
  mobileMenu,
  socialLinks,
  activeMenu,
  setActiveMenu,
  undoActiveMenu,
  undoMenuArray,
  activeTierIndex,
  menuArrayPostAnimation,
}: {
  mobileMenu: StoreMenuItem[];
  socialLinks?: SocialLink[];
  activeMenu: StoreMenuItem;
  setActiveMenu(data: StoreMenuItem): void;
  undoActiveMenu(): void;
  undoMenuArray(): void;
  activeTierIndex: number;
  menuArray: StoreMenuItem[];
  menuArrayPostAnimation: StoreMenuItem[];
}) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const { localizedSalonFinderRoute } = useGetSalonFinderRoute();
  const menuFooterRef = useRef<HTMLDivElement>(null);

  const isProAccountAllowed = hasLocaleSupportForProUsers(locale);

  useEffect(() => {
    if (menuFooterRef.current && menuFooterRef.current.parentElement) {
      const menusParent = menuFooterRef.current.parentElement;

      document.documentElement.style.setProperty(
        '--menu-drawer-links-height',
        `${menusParent.offsetHeight - menuFooterRef.current.offsetHeight}px`
      );
    }
  }, [menuFooterRef]);

  const telephoneLink = `tel:${t({
    id: 'menuDraw.footer.phone',
    message: '1-800-935-5273',
  })}`;

  const getProHref = () => {
    if (isChLocale(locale) || locale === 'en-GB') {
      return WELLASTORE_PROFESSIONAL_URL['promo-banner'][locale];
    }

    // Italian users should be redirected to the professional site
    if (locale === 'it-IT') {
      return WELLASTORE_PROFESSIONAL_URL[locale];
    }

    if (!isProAccountAllowed) {
      return WELLASTORE_PROFESSIONAL_URL[locale];
    }

    return '/account/login';
  };

  const renderLocaleSwitcher = () => {
    if (!isChLocale(locale)) {
      return null;
    }

    return <LocaleSwitcherContainer isMobile />;
  };

  const renderPhone = () => {
    const supportedLocales = ['en-US', 'en-GB'];
    if (!supportedLocales.includes(locale)) {
      return null;
    }

    return (
      <a
        href={telephoneLink}
        className={clsx(
          styles.menuDrawer__footerLink,
          'text-utility-utility-small'
        )}
      >
        <IconPhone />
        <span className="visually-hidden">
          <Trans id="menuDraw.footer.phoneLabel" message="Phone number" />
        </span>
        <Trans id="menuDraw.footer.phone" message="1-800-935-5273" />
      </a>
    );
  };

  return (
    <div className={styles.menuDrawer} data-test-id="menuDrawer">
      <div className={styles.menuDrawer__navigation}>
        <nav className={styles.menuDrawer__menus}>
          {activeTierIndex === 0 && (
            <MainMenuList
              {...{
                mobileMenu,
                activeMenu,
                setActiveMenu,
              }}
            />
          )}
          {activeTierIndex > 0 &&
            menuArrayPostAnimation.length > 1 &&
            ((activeMenu.menu && activeMenu.menu.length) ||
              (activeMenu.medias && activeMenu.medias.images.length)) && (
              <SubMenuList
                {...{
                  activeMenu,
                  setActiveMenu,
                  undoActiveMenu,
                  undoMenuArray,
                  activeTierIndex,
                }}
              />
            )}
        </nav>
        <div ref={menuFooterRef} className={styles.menuDrawer__footer}>
          <div className={styles.menuDrawer__footerLinks}>
            <Link
              href="/account/login"
              className={clsx(
                styles.menuDrawer__footerLink,
                'text-utility-utility-small'
              )}
              aria-label={t({
                id: 'menuDraw.footer.login',
                message: 'Log in / Sign up',
              })}
            >
              <IconAccount />
              <Trans id="menuDraw.footer.login" message="Log in / Sign up" />
            </Link>

            <Link
              href={localizedSalonFinderRoute}
              className={clsx(
                styles.menuDrawer__footerLink,
                'text-utility-utility-small'
              )}
            >
              <IconLocation />
              <Trans id="menuDraw.footer.salonFinder" message="Salon Finder" />
            </Link>

            {/* <button
              className={styles.menuDrawer__languageSwitcher}
              onClick={console.log}
              type="button"
              aria-label={t({
                id: 'storeSelector.open',
                message: 'Open Store Selector Modal',
              })}
            >
              <IconGB />
            </button> */}
          </div>

          <div
            className={clsx(
              styles.menuDrawer__footerLinks,
              styles['menuDrawer__footerLinks--dark']
            )}
          >
            <Link
              href="/account/wishlist"
              className={clsx(
                styles.menuDrawer__footerLink,
                'text-utility-utility-small'
              )}
              aria-label={t({
                id: 'menuDraw.footer.wishlist',
                message: 'Wishlist',
              })}
            >
              <IconHeart />
              <Trans id="menuDraw.footer.wishlist" message="Wishlist" />
            </Link>

            {renderPhone()}

            <Link
              href={getProHref()}
              className={clsx(
                styles.menuDrawer__footerLink,
                'text-utility-utility-small'
              )}
            >
              <IconProLogin />
              <Trans id="menuDraw.footer.proLogin" message="PRO LOG IN" />
            </Link>

            {renderLocaleSwitcher()}
          </div>

          {socialLinks?.length && (
            <SocialLinks
              links={socialLinks}
              className={styles.menuDrawer__footerSocials}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const MainMenuList = ({
  mobileMenu,
  activeMenu,
  setActiveMenu,
}: {
  mobileMenu: StoreMenuItem[];
  activeMenu: StoreMenuItem;
  setActiveMenu(data: StoreMenuItem): void;
}) => {
  const menuRefs = useRef<Record<string, HTMLUListElement>>({});
  const focusRefs = useRef<HTMLElement[]>([]);

  return (
    <ul
      key={'menuRoot'}
      ref={(element) => element && (menuRefs.current['menuRoot'] = element)}
      className={clsx(styles.menuDrawer__menu, {
        [styles.isActive]: activeMenu.id === 'menuRoot',
      })}
      data-menu-level="1"
    >
      {mobileMenu &&
        mobileMenu.map((tierOne) => {
          return (
            <li key={tierOne.id}>
              {((tierOne.menu && tierOne.menu.length) ||
                (tierOne.medias && tierOne.medias.images.length)) && (
                <OpenButton
                  tier={tierOne}
                  title={tierOne.title}
                  setActiveMenu={setActiveMenu}
                  ref={(element) => element && focusRefs.current.push(element)}
                  boldText={false}
                />
              )}

              {!(
                (tierOne.menu && tierOne.menu.length) ||
                (tierOne.medias && tierOne.medias.images.length)
              ) && (
                <MenuLink
                  title={tierOne.title}
                  url={tierOne.url}
                  image={tierOne.image}
                  ref={(element) => element && focusRefs.current.push(element)}
                  boldText={false}
                />
              )}
            </li>
          );
        })}
    </ul>
  );
};

const SubMenuList = ({
  activeMenu,
  setActiveMenu,
  undoActiveMenu,
  undoMenuArray,
  activeTierIndex,
}: {
  activeMenu: StoreMenuItem;
  setActiveMenu(data: StoreMenuItem): void;
  undoActiveMenu(): void;
  undoMenuArray(): void;
  activeTierIndex: number;
}) => {
  const focusRefs = useRef<HTMLElement[]>([]);

  useEffect(() => {
    setTimeout(() => {
      focusRefs.current[0]?.focus();
    }, 100);
  });

  return (
    <ul
      key={activeMenu.id}
      className={styles.menuDrawer__menu}
      data-menu-level={activeTierIndex + 1}
    >
      <li key={`${activeMenu.id}-back`}>
        <button
          onClick={() => {
            undoActiveMenu();
            undoMenuArray();
          }}
          className={clsx(
            styles.menuDrawer__link,
            styles.menuDrawer__button,
            styles.menuDrawer__back,
            'text-utility-utility-small',
            {
              [styles.menuDrawer__boldText]: activeTierIndex === 1,
            }
          )}
          aria-label="back"
          data-test-id="menuDrawerBackButton"
          ref={(element) => element && focusRefs.current.push(element)}
        >
          <IconChevronLeft />
          <span>{activeMenu.title}</span>
        </button>
      </li>

      {activeMenu.menu &&
        activeMenu.menu.map((tier) => {
          return (
            <li key={tier.id}>
              {(tier.menu || tier.medias) && (
                <OpenButton
                  tier={tier}
                  title={tier.title}
                  setActiveMenu={setActiveMenu}
                  ref={(element) => element && focusRefs.current.push(element)}
                  boldText={activeTierIndex === 1}
                />
              )}

              {!(tier.menu || tier.medias) && (
                <MenuLink
                  title={tier.title}
                  url={tier.url}
                  image={tier.image}
                  ref={(element) => element && focusRefs.current.push(element)}
                  boldText={activeTierIndex === 1}
                />
              )}
            </li>
          );
        })}

      {activeMenu.medias && (
        <li>
          <MenuDrawerMediaBlock medias={activeMenu.medias} />
        </li>
      )}
    </ul>
  );
};

const OpenButton = forwardRef<
  HTMLButtonElement | null,
  {
    tier: StoreMenuItem;
    title: string;
    setActiveMenu(menu: StoreMenuItem): void;
    boldText: boolean;
  }
>(({ tier, title, setActiveMenu, boldText }, ref) => {
  return (
    <button
      className={clsx(
        styles.menuDrawer__link,
        styles.menuDrawer__button,
        'text-utility-utility',
        {
          [styles.menuDrawer__boldText]: boldText,
        }
      )}
      onClick={() => {
        setActiveMenu(tier);
      }}
      data-test-id="menuDrawerButton"
      ref={ref}
    >
      {title}
      <IconChevronRight />
    </button>
  );
});

OpenButton.displayName = 'OpenButton';

const MenuLink = forwardRef<
  HTMLAnchorElement | null,
  {
    title: string;
    url?: string;
    image: ImageType | undefined;
    boldText: boolean;
  }
>(({ title, url, image, boldText }, ref) => {
  return (
    <Link
      href={url ?? ''}
      ref={ref}
      className={clsx(styles.menuDrawer__link, 'text-utility-utility', {
        [styles.menuDrawer__boldText]: boldText,
      })}
    >
      {image && (
        <Image
          src={image.url}
          alt={image.title}
          title={image.title}
          width={image.width}
          height={image.height}
        />
      )}
      <span>{title}</span>
    </Link>
  );
});

MenuLink.displayName = 'MenuLink';

/**
 * MenuDrawerMediaBlock component.
 * @param props - MenuDrawerMediaBlock component props.
 */
const MenuDrawerMediaBlock = ({ medias }: { medias?: MediaBlockType }) => {
  const submenuFirstItem = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setTimeout(() => {
      submenuFirstItem.current?.focus();
    }, 100);
  });

  return (
    <>
      {medias?.title && (
        <h2
          className={clsx(
            styles.menuDrawer__BlockHeading,
            'text-utility-utility'
          )}
        >
          {medias.title}
        </h2>
      )}

      <div className={styles.menuDrawer__BlockBody}>
        {(medias?.images || []).map((data, index: number) => (
          <Link
            href={data.url}
            key={index}
            target={data.newTab ? '_blank' : undefined}
            className={styles.menuDrawer__BlockColumn}
            ref={index == 0 ? submenuFirstItem : null}
            tabIndex={-1}
          >
            <div className={styles.menuDrawer__BlockColumnImage}>
              <Image
                src={data.image.url}
                alt={data.image.title}
                title={data.image.title}
                width={129}
                height={129}
              />
            </div>
            <p className="text-body-2-bold">{data.title}</p>
          </Link>
        ))}
      </div>
    </>
  );
};
