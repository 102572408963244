import { Portal } from '@reach/portal';
import type { ReactNode } from 'react';

export const OverlayFallback = ({ children }: { children: ReactNode }) => {
  return (
    <Portal>
      <div
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
        style={{ display: 'none' }}
      >
        {children}
      </div>
    </Portal>
  );
};

export default OverlayFallback;
