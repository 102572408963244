/**
 * Every util related to localization differences
 */
import type { StoreLocale } from '@/root/constants';
import { isChLocale } from '@/root/constants';
import { getLangFromLocale } from 'backend/utils/helpers';
import { CurrencyCode } from 'lib/shopify-storefront/__generated__/types';
import type { AlgoliaProductHitParams } from 'utils/format/algoliaHit';

export const formatMoney = (
  locale = 'en-US',
  currencyCode: string,
  amount: number
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount);
};
export const getCountryCodeFromLocale = (locale: string) => {
  return locale.split('-')[1];
};

/**
 * Sometimes we need to rewrite jp to ja for compatibility with eg. Contentful
 */
export const rewriteJpLocale = (locale: StoreLocale): StoreLocale => {
  if (locale === 'jp-JP') {
    return 'ja-JP' as StoreLocale;
  }
  return locale;
};

export const getCurrencyCode = (locale: StoreLocale) => {
  const localeToCurrency: Record<StoreLocale, CurrencyCode> = {
    'en-US': CurrencyCode.Usd,
    'en-GB': CurrencyCode.Gbp,
    'en-CH': CurrencyCode.Chf,
    'de-CH': CurrencyCode.Chf,
    'fr-CH': CurrencyCode.Chf,
    'it-CH': CurrencyCode.Chf,
    'fr-FR': CurrencyCode.Eur,
    'de-DE': CurrencyCode.Eur,
    'it-IT': CurrencyCode.Eur,
    'es-ES': CurrencyCode.Eur,
    'jp-JP': CurrencyCode.Jpy,
  };
  return localeToCurrency[locale] || CurrencyCode.Gbp;
};

export const getLocalizedFields = (
  algoliaHitParams: AlgoliaProductHitParams
) => {
  const lang = getLangFromLocale(algoliaHitParams.locale as StoreLocale);
  let { handle, meta, title } = algoliaHitParams.hit;
  if (isChLocale(algoliaHitParams.locale as StoreLocale)) {
    handle = algoliaHitParams.hit[`handle_${lang}`] ?? handle;
    title = algoliaHitParams.hit[`title_${lang}`] ?? title;
    meta = algoliaHitParams.hit[`meta_${lang}`] ?? meta;
  }
  return { handle, meta, title };
};
