import type { NextApiRequest } from 'next';
import type { StoreLocale } from '@/root/constants';

export const queryValueToString = (key: NextApiRequest['query']['key']) => {
  if (!key) return '';

  if (Array.isArray(key)) {
    return key[0];
  }

  return key;
};

export const getLangFromLocale = (locale: StoreLocale) =>
  locale.split('-').at(0);

export const mapTranslations = (
  translations: Array<{ key: string; value?: string | null }>
) =>
  translations.reduce<Record<string, string | null | undefined>>(
    (acc, translation) => {
      acc[translation.key] = translation.value;
      return acc;
    },
    {}
  );
