import { useInterpret } from '@xstate/react';
import type { ReactNode } from 'react';
import React from 'react';
import type { Simplify } from 'type-fest';
import { createContext } from 'use-context-selector';
import { assign } from 'xstate';
import type { InterpreterFrom, StateFrom } from 'xstate';
import type { StoreLocale, StoreFeatureFlags } from '@/root/constants';
import { localeToStore } from '@/root/constants';
import {
  assignCart,
  assignCartErrors,
  assignCartId,
  setCart,
  setCartId,
  assignCartLocale,
} from 'components/storeContext/actions/cartActions';
import {
  assignCustomer,
  assignCustomerBlank,
  assignCustomerError,
  assignCustomerRestored,
  assignCustomerToken,
  setCustomer,
  setCustomerClear,
  setCustomerToken,
} from 'components/storeContext/actions/customerActions';
import {
  cartById,
  cartCreate,
  addCartAttributes,
  cartIdentityUpdate,
  cartInit,
  cartLineAdd,
  cartGiftsHandle,
  cartDiscountCheckHandle,
  cartFindVariant,
  cartLineRemove,
  cartLineUpdate,
  cartDiscountCodesUpdate,
  giftTriggerAfterCustomerStatusChange,
  cartReinit,
} from 'components/storeContext/services/cartServices';
import {
  customerActivate,
  customerCreate,
  customerFetch,
  customerInit,
  customerReset,
  customerRecover,
  customerSignIn,
  customerTokenRenew,
  customerSignOut,
} from 'components/storeContext/services/customerServices';
import { storeMachine } from 'components/storeContext/storeMachine';

export type StoreState = Simplify<StateFrom<typeof storeMachine>>;

export type StoreServiceType = Simplify<InterpreterFrom<typeof storeMachine>>;

type StoreContextType = {
  storeService: StoreServiceType;
};

export const StoreContext = createContext<StoreContextType>({
  storeService: {} as StoreServiceType,
});

export const StoreProvider = ({
  locale,
  query,
  children,
  featureFlags,
}: {
  locale: StoreLocale;
  query?: Record<string, unknown>;
  featureFlags: StoreFeatureFlags;
  children: ReactNode;
}) => {
  const store = localeToStore(locale);
  const storeService = useInterpret(storeMachine, {
    devTools:
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_XSTATE_DEBUG === 'true',
    context: {
      locale,
      query,
      featureFlags,
      store,
      customerErrors: null,
    },
    services: {
      cartInit,
      cartCreate,
      addCartAttributes,
      cartLineAdd,
      cartGiftsHandle,
      cartDiscountCheckHandle,
      cartFindVariant,
      cartLineRemove,
      cartLineUpdate,
      cartDiscountCodesUpdate,
      giftTriggerAfterCustomerStatusChange,
      cartById,
      cartIdentityUpdate,
      customerActivate,
      customerCreate,
      customerInit,
      customerSignIn,
      customerTokenRenew,
      customerFetch,
      customerReset,
      customerRecover,
      customerSignOut,
      cartReinit,
    },
    actions: {
      assignCustomerBlank,
      assignCart,
      assignCartErrors,
      assignCartId,
      assignCustomer,
      assignCustomerError,
      assignCustomerToken,
      assignCustomerRestored,
      clearErrorMessage: assign((_) => ({
        cartErrors: null,
        customerErrors: null,
      })),
      assignCustomerResetToken: assign((_) => ({})),
      assignCustomerActivationToken: assign((_) => ({})),
      updateLocalCart: assign((_) => ({})),
      setCustomerClear,
      setCustomer,
      setCustomerToken,
      setCart,
      setCartId,
      assignCartLocale,
    },
  });

  return (
    <StoreContext.Provider value={{ storeService }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
