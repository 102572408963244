import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { createContext, useContext } from 'use-context-selector';
import type { StoreState } from 'components/storeContext/StoreContext';
import { StoreContext } from 'components/storeContext/StoreContext';
import type {
  Maybe,
  StoreCustomer,
} from 'components/storeContext/storeMachine';

type ContextType = {
  customerId: Maybe<string>;
  customerSignOut(): void;
  customerLoading: boolean;
  proCustomer: boolean;
  approvalPendingCustomer: boolean;
  signedIn: boolean;
  signedOut: boolean;
  customer: StoreCustomer | null;
  customerAccessToken: string;
};

export const CustomerContext = createContext<ContextType>({
  customerId: null,
  customerSignOut: () => {
    return;
  },
  customerLoading: false,
  proCustomer: false,
  approvalPendingCustomer: false,
  signedIn: false,
  signedOut: true,
  customer: null,
  customerAccessToken: '',
});

const selectCustomerId = (state: StoreState) =>
  state.context.customerId?.[state.context.store] ?? '';

const selectSignedIn = (state: StoreState) =>
  state.matches('customerMachine.signedIn.active') ||
  state.matches('customerMachine.signedIn.success');

const selectCustomerLoading = (state: StoreState) =>
  state.matches('customerMachine.fetching') ||
  state.matches('customerMachine.initialise');

const selectSignedOut = (state: StoreState) =>
  state.matches('customerMachine.idle.noError');

const selectCustomerErrored = (state: StoreState) =>
  state.matches('customerMachine.idle.errored');

const selectCustomerProStatus = (state: StoreState) =>
  !!state.context.customer?.[state.context.store]?.pro ?? false;

const selectCustomerStatus = (state: StoreState) =>
  !!state.context.customer?.[state.context.store]?.approvalPending;

const selectCustomer = (state: StoreState) =>
  state.context.customer?.[state.context.store] ?? null;

const selectCustomerAccessToken = (state: StoreState) =>
  state.context.customerAccessToken?.[state.context.store] ?? '';

export const CustomerProvider = ({ children }: { children: ReactNode }) => {
  const { storeService } = useContext(StoreContext);
  const { asPath } = useRouter();

  const customerId = useSelector(storeService, selectCustomerId);
  const signedIn = useSelector(storeService, selectSignedIn);
  const signedOut = useSelector(storeService, selectSignedOut);
  const customerLoading = useSelector(storeService, selectCustomerLoading);
  const proCustomer = useSelector(storeService, selectCustomerProStatus);
  const approvalPendingCustomer = useSelector(
    storeService,
    selectCustomerStatus
  );
  const customer = useSelector(storeService, selectCustomer);
  const customerErrored = useSelector(storeService, selectCustomerErrored);

  const customerAccessToken = useSelector(
    storeService,
    selectCustomerAccessToken
  );

  const customerSignOut = () => {
    storeService.send('CUSTOMER_SIGN_OUT');
  };

  /**
   * Watch for route changes and clear the customer errors if the user changes
   * route.
   */
  useEffect(() => {
    if (customerErrored) {
      storeService.send('CUSTOMER_ERROR_CLEAR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return (
    <CustomerContext.Provider
      value={{
        customerId,
        customerSignOut,
        signedIn,
        signedOut,
        customerLoading,
        proCustomer,
        approvalPendingCustomer,
        customer,
        customerAccessToken,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;

export const useCustomer = () => {
  return useContext(CustomerContext);
};
