/* eslint-disable @typescript-eslint/naming-convention */
import type { I18n, Messages } from '@lingui/core';
import { de, en, fr, it, es, ja } from 'make-plural/plurals';
import { storeLocale } from '@/root/constants';

// announce which locales we are going to use and connect them to appropriate plural rules
export function initTranslation(i18n: I18n): void {
  i18n.loadLocaleData({
    [storeLocale('en-GB')]: { plurals: en },
    [storeLocale('en-US')]: { plurals: en },
    [storeLocale('en-CH')]: { plurals: en },
    [storeLocale('fr-CH')]: { plurals: fr },
    [storeLocale('de-CH')]: { plurals: de },
    [storeLocale('it-CH')]: { plurals: it },
    [storeLocale('de-DE')]: { plurals: de },
    [storeLocale('it-IT')]: { plurals: it },
    [storeLocale('fr-FR')]: { plurals: fr },
    [storeLocale('es-ES')]: { plurals: es },
    [storeLocale('jp-JP')]: { plurals: ja },
  });
}
/**
 * Load Translation File based on locale and enviroment
 *
 * @param locale: string
 * @param isProduction: boolean
 * @returns: {messages: object}
 */
export async function loadTranslation(
  locale: string,
  isProduction = true
): Promise<Messages> {
  let storefrontTranslation = { messages: {} };
  let uilibTranslations = { messages: {} };

  let messages = {};

  let selectedLocale = locale;
  if (locale === 'jp-JP') {
    // Replace jp for ja to properly indicate language code
    selectedLocale = 'ja-JP';
  }

  /**
   * If enviroment is production pulled in compiled js files and spread the
   * resulting messages.
   */
  if (isProduction) {
    await import(
      `@/public/shared-assets/locales/${selectedLocale}/storefront-app`
    ).then((messages) => {
      storefrontTranslation = messages;
    });
    await import(
      `@/public/shared-assets/locales/${selectedLocale}/ui-lib`
    ).then((messages) => {
      uilibTranslations = messages;
    });

    messages = {
      ...uilibTranslations.messages,
      ...storefrontTranslation.messages,
    };
  } else {
    /**
     * If enviroment is development pull in raw json files and spread them into
     * resulting messages.
     */
    await import(
      `@/public/shared-assets/locales/${selectedLocale}/storefront-app.json`
    ).then((messages) => {
      storefrontTranslation = messages;
    });
    await import(
      `@/public/shared-assets/locales/${selectedLocale}/ui-lib.json`
    ).then((messages) => {
      uilibTranslations = messages;
    });

    messages = { ...uilibTranslations, ...storefrontTranslation };
  }

  return messages;
}
