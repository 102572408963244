/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const sendDataToGTM = (data: Record<string, any>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
