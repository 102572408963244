import globalStyles from '../styles/globalStyles';

export const keyboardAccessibility = () => {
  const init = () => {
    resetTabClass();
    setEventListeners();
  };

  const resetTabClass = () => {
    document.body.classList.remove(globalStyles.accessbility.tabbable);
  };

  /**
   * Set keyboard listeners.
   */
  const setEventListeners = () => {
    document.addEventListener('keydown', handleKeyboardEvent);
  };

  /**
   * Handle keyboard events after validating Tab.
   * @param {Event} event - Keyboard keydown event.
   */
  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (isKeyPressIsTab(event)) {
      handleTabEvent();
    }
  };

  /**
   * Check if Tab key has been pressed.
   * @param {Event} event - Keyboard event passed from user input on document.
   */
  const isKeyPressIsTab = (event: KeyboardEvent) => {
    if (!event.key) return;

    return event.key.toLowerCase() === 'tab';
  };

  /**
   * Handle Tab keyboard event.
   * @param {Event} event - Key code event set on document.
   */
  const handleTabEvent = () => {
    document.body.classList.add(globalStyles.accessbility.tabbable);
    document.removeEventListener('keydown', handleKeyboardEvent);
  };

  init();
};
