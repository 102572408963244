import type { Messages } from '@lingui/core';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import type { DehydratedState } from '@tanstack/react-query';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { FEATURE_FLAGS, storeLocale } from '@/root/constants';
import { CartProvider } from 'components/cartContext/CartContext';
import { CustomerProvider } from 'components/customerContext/CustomerContext';
import { FeatureFlagsProvider } from 'components/featureFlagsProvider/FeatureFlagsContext';
import { GlobalContentProvider } from 'components/globalContentContext/GlobalContentContext';
import { MenuProvider } from 'components/menuContext/MenuContext';
import { OverlayProvider } from 'components/overlay/OverlayContext';
import { StoreProvider } from 'components/storeContext/StoreContext';
import type { FeatureFlagsQuery } from 'lib/contentful/__generated__/SettingsQuery';
import { useFeatureFlagsQuery } from 'lib/contentful/__generated__/SettingsQuery';
import { WishlistProvider } from './components/wishlistContext/WishlistContext';
import { initTranslation } from './utils/lingui';

initTranslation(i18n);

export const AppProviders = ({
  children,
  dehydratedState,
  translation,
}: {
  children: ReactNode;
  dehydratedState?: DehydratedState;
  translation?: Messages;
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const router = useRouter();
  const locale = storeLocale(router.locale || router.defaultLocale);
  const firstRender = useRef(true);

  const preview = !!router.query.preview;

  // run only once on the first render (for server side)
  if (translation && firstRender.current) {
    i18n.load(locale, translation);
    i18n.activate(locale);
    firstRender.current = false;
  }

  // listen for the locale changes
  useEffect(() => {
    if (translation) {
      i18n.load(locale, translation);
      i18n.activate(locale);
    }
  }, [locale, translation]);

  /**
   * Feature Flag Detection
   *
   * Feature flags are pulled from dehydrated state otherwise the constant default is provided.
   */
  // Load default feature flags
  const featureFlags = FEATURE_FLAGS;

  // Get the query key of our locales feature flags
  const featureFlagsQueryKey = useFeatureFlagsQuery.getKey({ locale });
  // Hash that query key for use in find
  const featureFlagsQueryKeyHash = JSON.stringify(featureFlagsQueryKey);
  // Find the locale based feature flags from dehydrated state with an empty fallback
  // The type of the found query is cast to match the query
  const localeFeatureFlagsQuery =
    (dehydratedState?.queries.find(
      (query) => query.queryHash === featureFlagsQueryKeyHash
    )?.state.data as FeatureFlagsQuery) ?? null;
  const localeFeatureFlags =
    localeFeatureFlagsQuery?.featureFlagsCollection?.items[0]?.features ?? {};

  // Override locale feature flags, spreading in the default before spreading in the value from dehydrated state.
  featureFlags[locale] = { ...featureFlags[locale], ...localeFeatureFlags };

  return (
    <StoreProvider
      locale={locale}
      query={router.query}
      featureFlags={featureFlags}
    >
      <FeatureFlagsProvider>
        <I18nProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition="bottom-left"
              />
            )}

            <Hydrate state={dehydratedState}>
              <CustomerProvider>
                <OverlayProvider>
                  <CartProvider>
                    <WishlistProvider locale={locale}>
                      <GlobalContentProvider
                        locale={locale}
                        byPassCache={preview}
                      >
                        <MenuProvider locale={locale}>{children}</MenuProvider>
                      </GlobalContentProvider>
                    </WishlistProvider>
                  </CartProvider>
                </OverlayProvider>
              </CustomerProvider>
            </Hydrate>
          </QueryClientProvider>
        </I18nProvider>
      </FeatureFlagsProvider>
    </StoreProvider>
  );
};

export default AppProviders;
