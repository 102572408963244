import { useRouter } from 'next/router';
import type { StoreLocale } from '@/root/constants';
import { storeLocale } from '@/root/constants';

import { getCountryCodeFromLocale } from 'utils/localization';

export function useGetSalonFinderRoute() {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const salonFinderRoute = `/salon-finder`;

  /**
   * Generate the salon finder page URL
   * - Generates the salon finder page url with appropriate country parameter
   *   based on the given locale.
   * - Note: GB does not need the country parameter as it is added by default.
   * @param locale store locale
   * @returns string - URL for the Salon Finder page
   */
  const getSalonFinderRouteByLocale = (locale: StoreLocale) => {
    const country = getCountryCodeFromLocale(locale);

    if (country) {
      return `${salonFinderRoute}?country=${country}`;
    } else {
      return salonFinderRoute;
    }
  };

  const localizedSalonFinderRoute = getSalonFinderRouteByLocale(locale);

  return {
    getSalonFinderRouteByLocale,
    localizedSalonFinderRoute,
  };
}
