import type { StoreLocale } from '@/root/constants';
import {
  CountryCode,
  LanguageCode,
} from 'lib/shopify-storefront/__generated__/types';

/**
 * Locale to Country and Language
 *
 * Shopifies storefront API requires locale codes to be split
 * This function splits our locale string and validates the results against
 * our storefront enums. This does involve casting our result if it is known
 * to be 'good'
 *
 * @param locale
 * @returns
 */
export function localeToCountryLanguage(locale: StoreLocale): {
  country: CountryCode;
  language: LanguageCode;
} {
  const localeSplit = locale.split('-');

  const language: LanguageCode | string = localeSplit[0].toUpperCase();
  const country: CountryCode | string = localeSplit[1].toUpperCase();

  const codes: { country: CountryCode; language: LanguageCode } = {
    country: CountryCode['Us'],
    language: LanguageCode['En'],
  };

  if (Object.values(CountryCode).includes(country as unknown as CountryCode)) {
    codes.country = country as CountryCode;
  }

  if (
    Object.values(LanguageCode).includes(language as unknown as LanguageCode)
  ) {
    codes.language = language as LanguageCode;
  }

  return codes;
}
