const globalStyles = {
  isLoading: 'is-loading',
  ['isLoading--inline']: 'is-loading--inline',
  ['isLoading--inline-flex']: 'is-loading--inline-flex',
  layout: {
    grid: 'grid',
    container: 'container',
    componentSection: 'component-section',
    col: 'col',
    l: {
      l1: 'l1',
      l2: 'l2',
      l3: 'l3',
      l4: 'l4',
      l5: 'l5',
      l6: 'l6',
      l7: 'l7',
      l8: 'l8',
      l9: 'l9',
      l10: 'l10',
      l11: 'l11',
      l12: 'l12',
    },
    m: {
      m1: 'm1',
      m2: 'm2',
      m3: 'm3',
      m4: 'm4',
      m5: 'm5',
      m6: 'm6',
    },
    s: {
      s1: 's1',
      s2: 's2',
      s3: 's3',
      s4: 's4',
    },
    xs: {
      xs1: 'xs1',
      xs2: 'xs2',
      xs3: 'xs3',
      xs4: 'xs4',
    },
  },
  text: {
    body: {
      s: 'text-body-s',
      sDesktop: 'text-body-s-desktop',
      m: 'text-body-m',
      mDesktop: 'text-body-m-desktop',
      label: 'text-body-label',
    },
    heading: {
      l: 'text-heading-l',
      xl: 'text-heading-xl',
      textHeading2Xl: 'text-heading-2xl',
      textHeading2XlDesktop: 'text-heading-2xl-desktop',
    },
  },
  accessbility: {
    tabbable: 'is-tabbable',
  },
};

export default globalStyles;
