import { useRouter } from 'next/router';

export function useGetPageType() {
  const { asPath } = useRouter();

  if (asPath === '/') return 'Homepage';
  else if (asPath.indexOf('/collections/') > -1) return 'Product listing page';
  else if (asPath.indexOf('/products/') > -1) return 'Product details page';
  else if (asPath === '/blog') return 'Blog landing page';
  else if (asPath.startsWith('/blog')) return 'Blog post page';
  else if (asPath === '/nail-art') return 'Nail art landing page';
  else if (asPath === '/nail-art/[handle]') return 'Nail art content page';
  else if (asPath === '/search') return 'Search page';
  else if (asPath.startsWith('/account')) return 'Account page';
  else return 'Misc.';
}
