/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Project Constants
 *
 * This file defines strict constants used throughout the project in a type-safe way
 * For instance the list of allowed locales is defined here along with default feature flags.
 * LOCALES are defined in packages/common-i18n config
 */

/* List of constant values */
import { i18n } from '@lingui/core';

// opi-ch-development is a PRODUCTION store, DO NOT CHANGE THIS
export const opiChStore = 'opi-ch-development';

export const LOCALES = [
  'en-US',
  'en-GB',
  'en-CH',
  'fr-CH',
  'de-CH',
  'it-CH',
  'fr-FR',
  'de-DE',
  'it-IT',
  'es-ES',
  'jp-JP',
] as const;
export const STORES = [
  'opi-uki',
  'opi-us',
  opiChStore,
  'opi-de',
  'opi-it',
  'opi-fr',
  'opi-es',
  'opi-jp',
] as const;
export const DEFAULT_LOCALE: StoreLocale = 'en-US';
export const DEFAULT_STORE: Stores = 'opi-us';
export const FEATURES = [
  'cartEnabled',
  'amazonCart',
  'backInStock',
  'increasingly',
  'showPrice',
  'mikmakEnabled',
  'isProProductFilteringEnabled',
  'isFooterNewsletterEnabled',
] as const;
export const SDS_LANGUAGES = [
  'english',
  'spanish',
  'vietnamese',
  'chinese',
  'korean',
];

export const LOCALES_TO_STORES = new Map<StoreLocale, Stores>([
  ['en-GB', 'opi-uki'],
  ['en-US', 'opi-us'],
  ['de-DE', 'opi-de'],
  ['it-IT', 'opi-it'],
  ['fr-FR', 'opi-fr'],
  ['es-ES', 'opi-es'],
  ['jp-JP', 'opi-jp'],
  ['en-CH', opiChStore],
  ['fr-CH', opiChStore],
  ['de-CH', opiChStore],
  ['it-CH', opiChStore],
]);

export const STORES_TO_ACCESS_TOKEN = new Map<Stores, string>([
  ['opi-uki', process.env.NEXT_PUBLIC_EN_GB_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-us', process.env.NEXT_PUBLIC_EN_US_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-de', process.env.NEXT_PUBLIC_DE_DE_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-it', process.env.NEXT_PUBLIC_IT_IT_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-fr', process.env.NEXT_PUBLIC_FR_FR_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-es', process.env.NEXT_PUBLIC_ES_ES_STOREFRONT_ACCESS_TOKEN ?? ''],
  ['opi-jp', process.env.NEXT_PUBLIC_JP_JP_STOREFRONT_ACCESS_TOKEN ?? ''],
  [opiChStore, process.env.NEXT_PUBLIC_CH_STOREFRONT_ACCESS_TOKEN ?? ''],
]);

// Order of elements in array defines order in locale switcher
export const CH_LOCALES = ['de-CH', 'fr-CH', 'it-CH', 'en-CH'] as const;

export const FEATURE_FLAGS: StoreFeatureFlags = {
  'en-GB': {
    cartEnabled: true,
    amazonCart: false,
    backInStock: false,
    increasingly: true,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'en-US': {
    cartEnabled: true,
    amazonCart: true,
    backInStock: false,
    increasingly: false,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'en-CH': {
    cartEnabled: true,
    amazonCart: false,
    backInStock: true,
    increasingly: true,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'fr-CH': {
    cartEnabled: true,
    amazonCart: false,
    backInStock: true,
    increasingly: true,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'de-CH': {
    cartEnabled: true,
    amazonCart: false,
    backInStock: true,
    increasingly: true,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'it-CH': {
    cartEnabled: true,
    amazonCart: false,
    backInStock: true,
    increasingly: true,
    showPrice: true,
    mikmakEnabled: false,
    isProProductFilteringEnabled: true,
    isFooterNewsletterEnabled: true,
  },
  'fr-FR': {
    cartEnabled: false,
    amazonCart: false,
    backInStock: false,
    increasingly: false,
    showPrice: false,
    mikmakEnabled: true,
    isProProductFilteringEnabled: false,
    isFooterNewsletterEnabled: true,
  },
  'de-DE': {
    cartEnabled: false,
    amazonCart: false,
    backInStock: false,
    increasingly: false,
    showPrice: false,
    mikmakEnabled: false,
    isProProductFilteringEnabled: false,
    isFooterNewsletterEnabled: true,
  },
  'it-IT': {
    cartEnabled: false,
    amazonCart: false,
    backInStock: false,
    increasingly: false,
    showPrice: false,
    mikmakEnabled: false,
    isProProductFilteringEnabled: false,
    isFooterNewsletterEnabled: true,
  },
  'es-ES': {
    cartEnabled: false,
    amazonCart: false,
    backInStock: false,
    increasingly: false,
    showPrice: false,
    mikmakEnabled: true,
    isProProductFilteringEnabled: false,
    isFooterNewsletterEnabled: true,
  },
  'jp-JP': {
    cartEnabled: false,
    amazonCart: false,
    backInStock: false,
    increasingly: false,
    showPrice: false,
    mikmakEnabled: false,
    isProProductFilteringEnabled: false,
    isFooterNewsletterEnabled: true,
  },
};

/* Algolia Constants */
export const ALGOLIA_PRODUCTS_BASE = '--shopify_products' as const;
export const ALGOLIA_COLLECTION_BASE = '--shopify_collections' as const;
export const ALGOLIA_ARTICLES_BASE = '--contentful_articles' as const;
export const ALGOLIA_NAILART_BASE = '--contentful_nail_art' as const;
export const ALGOLIA_SUGGESTIONS_BASE =
  '--shopify_products_query_suggestions' as const;
export const ALGOLIA_EDU_COURSE_BASE = '--contentful_edu_course' as const;

/**
 * Constant Types
 *
 * Types derived from our constant values
 */
export type StoreLocale = (typeof LOCALES)[number];
export type StoreFeatures = (typeof FEATURES)[number];
export type Stores = (typeof STORES)[number];

export type StoreLocalesSources = Record<StoreLocale, Stores>;

export type StoreFeatureFlags = Record<
  StoreLocale,
  Record<StoreFeatures, boolean>
>;

/**
 * storeLocale function
 *
 * This function accepts a string input and returns a valid 'StoreLocale' value
 * if the provided string does not match a locale the default is returned.
 */
export const storeLocale = (locale: unknown): StoreLocale => {
  if (
    typeof locale === 'string' &&
    (LOCALES as ReadonlyArray<string>).includes(locale)
  )
    return locale as StoreLocale;

  return DEFAULT_LOCALE;
};

/**
 * stores function
 *
 * Returns an accepted shopify store value as string from an unknown type
 */
export const stores = (store: unknown): string => {
  if (
    typeof store === 'string' &&
    (LOCALES as ReadonlyArray<string>).includes(store)
  )
    return store as string;

  return 'default';
};

/**
 * Locale to store function
 *
 * Returns the current Shopify Store ID of a give locale
 * Please note this map must be manually updated
 */
export const localeToStore = (locale: StoreLocale): Stores => {
  return LOCALES_TO_STORES.get(locale) ?? DEFAULT_STORE;
};

/**
 * Locale to store function
 *
 * Returns the current Shopify Store ID of a give locale
 * Please note this map must be manually updated
 */
export const storeToAccessToken = (store: Stores): string => {
  return STORES_TO_ACCESS_TOKEN.get(store) ?? '';
};

/**
 * isChLocale function
 *
 * This function accepts a string locale input and returns if locale is part of Swiss
 */
export const isChLocale = (
  locale: StoreLocale
): locale is 'en-CH' | 'de-CH' | 'fr-CH' | 'it-CH' => {
  return CH_LOCALES.includes(locale as 'en-CH' | 'de-CH' | 'fr-CH' | 'it-CH');
};

type LocaleApprovalInfo = {
  isApprovalRequired: boolean;
};

export const localesWithProUsers: Partial<
  Record<StoreLocale, LocaleApprovalInfo>
> = {
  'en-US': { isApprovalRequired: false },
  'it-IT': { isApprovalRequired: false },
  'jp-JP': { isApprovalRequired: false },
};

// Returns the list of locales that does not have the interest form for pro users
export const localesWithoutInterestFormForProUsers: Array<StoreLocale> = [
  'it-IT',
  // 'jp-JP',
];

// Returns the list of locales that does not have the pro type select field for pro users
export const localesWithoutProTypeSelectForProUsers: Array<StoreLocale> = [
  'it-IT',
  // 'jp-JP',
];

// Returns the list of locales that does not have the license form field for pro users
export const localesWithoutLicenseFormForProUsers: Array<StoreLocale> = [];

export const hasLocaleSupportForProUsers = (locale: StoreLocale): boolean =>
  !!localesWithProUsers[locale];

export const isApprovalRequiredForProUsers = (locale: StoreLocale): boolean => {
  const localeInfo = localesWithProUsers[locale];
  return localeInfo ? localeInfo.isApprovalRequired : false;
};

export type CODE = {
  name: string;
  code: string;
};

export const PRO_TYPES: Array<CODE> = [
  {
    name: 'Salon Owner',
    code: 'salon_owner',
  },
  {
    name: 'Nail Technician - Salon',
    code: 'nail_technician_salon',
  },
  {
    name: 'Independent Nail Technician',
    code: 'independent_nail_t',
  },
  {
    name: 'Salon Manager',
    code: 'salon_manager',
  },
  {
    name: 'Nail Student',
    code: 'nail_student',
  },
  {
    name: 'Nail Educator',
    code: 'nail_educator',
  },
  {
    name: 'N/A',
    code: 'professional_type_na',
  },
];

export const SALON_TYPES: Array<CODE> = [
  {
    name: 'Home Salon',
    code: 'company_type_home',
  },
  {
    name: 'High Street Salon',
    code: 'company_type_high_street',
  },
  {
    name: 'Spa',
    code: 'company_type_spa',
  },
  {
    name: 'Mobile therapist',
    code: 'company_type_mobile',
  },
  {
    name: 'Student',
    code: 'company_type_student',
  },
  {
    name: 'Pharmacist',
    code: 'company_type_pharmacist',
  },
  {
    name: 'College',
    code: 'company_type_college',
  },
];

export const GB_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    name: 'Guernsey',
    code: 'GG',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
];

export const DE_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'Germany',
    code: 'DE',
  },
];

export const FR_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'France',
    code: 'FR',
  },
];

export const ES_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'España',
    code: 'ES',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
];

export const IT_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'Italy',
    code: 'IT',
  },
];

export const JP_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: 'Japan',
    code: 'JP',
  },
];

export const CH_LOCALE_COUNTRIES: Array<CODE> = [
  {
    name: i18n._(
      'page.register.country.ch',
      {},
      {
        message: 'Switzerland',
      }
    ),
    code: 'CH',
  },
];

export const CH_LOCALE_PREFERRED_LANGUAGE: Array<CODE> = [
  {
    name: i18n._(
      'page.register.preferredLanguage.fr',
      {},
      {
        message: 'French',
      }
    ),
    code: 'FR',
  },
  {
    name: i18n._(
      'page.register.preferredLanguage.de',
      {},
      {
        message: 'German',
      }
    ),
    code: 'DE',
  },
  {
    name: i18n._(
      'page.register.preferredLanguage.en',
      {},
      {
        message: 'English',
      }
    ),
    code: 'EN',
  },
  {
    name: i18n._(
      'page.register.preferredLanguage.it',
      {},
      {
        message: 'Italian',
      }
    ),
    code: 'IT',
  },
];

export const STARLIGHT_WARRANTY_COUNTRIES: Array<CODE> = [
  {
    name: 'United States',
    code: 'US',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
];

export const DOB_SUPPORTED_LOCALES: Array<StoreLocale> = [];

export const WELLASTORE_PROFESSIONAL_URL = {
  'en-CH': 'https://ch.wella.professionalstore.com/',
  'de-CH': 'https://ch.wella.professionalstore.com/de-CH',
  'fr-CH': 'https://ch.wella.professionalstore.com/fr-CH',
  'it-CH': 'https://ch.wella.professionalstore.com/',
  'en-GB': 'https://uk.wella.professionalstore.com/',
  'en-US': '/account/login',
  'de-DE': 'https://www.b2b.colibri-beauty.de/',
  'it-IT': 'https://www.opiitalia.it',
  'fr-FR': 'https://fr.wella.professionalstore.com/category/opi',
  'es-ES': 'https://es.wella.professionalstore.com/',
  'jp-JP': 'https://pro.opijapan.com/',
  'promo-banner': {
    'en-CH': 'https://ch.wella.professionalstore.com/de-CH/opi',
    'de-CH': 'https://ch.wella.professionalstore.com/de-CH/opi',
    'fr-CH': 'https://ch.wella.professionalstore.com/fr-CH/opi', // right now only French one has a different URL https://dentsu-poland.atlassian.net/browse/NPOT-335
    'it-CH': 'https://ch.wella.professionalstore.com/de-CH/opi',
    'en-GB': 'https://uk.wella.professionalstore.com/',
  },
  'create-account': {
    'en-CH': 'https://ch.wella.professionalstore.com/de-CH/create-account',
    'de-CH': 'https://ch.wella.professionalstore.com/de-CH/create-account',
    'fr-CH': 'https://ch.wella.professionalstore.com/fr-CH/create-account', // right now only French one has a different URL https://dentsu-poland.atlassian.net/browse/NPOT-335
    'it-CH': 'https://ch.wella.professionalstore.com/de-CH/create-account',
    'en-GB': 'https://uk.wella.professionalstore.com/',
  },
} as const;

// List of locales that only have the wishlist feature, no orders or addresses
export const localesWithOnlyWishlist: Array<StoreLocale> = [
  'fr-FR',
  'de-DE',
  'es-ES',
  'it-IT',
  'jp-JP',
];

export const localesWithBazaarVoice: Array<StoreLocale> = [
  'en-GB',
  'fr-FR',
  'en-CH',
  'de-CH',
  'fr-CH',
  'it-CH',
];
