import type { Messages } from '@lingui/core';
import type { DehydratedState } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import aa from 'search-insights';
import {
  LOCALES,
  storeLocale as getStoreLocale,
  localesWithBazaarVoice,
} from '@/root/constants';
import MainLayout from 'components/layout/Layout';
import { Swaven } from 'components/swaven/swaven';
import XstateDebug from 'components/xstateDebug/XstateDebug';

import { getLocaleFormatted } from 'utils/bv/helpers';
import { getSeoData } from 'utils/seo/getSeoData/getSeoData';
import { AppProviders } from '../AppProviders';

import 'focus-visible';
import 'styles/critical/theme.scss';
import 'styles/layout/theme.scss';
import 'styles/fonts.css';

// dynamic import popup
const SignUpPopup = dynamic(() => import('components/signUpPopup/signUpPopup'));

// This is used by next-fixture.ts to pass requestInterceptor to each test,
// where it can be used to set up the server-side request mocks.
export const requestInterceptor =
  process.env.PLAYWRIGHT === '1' && typeof window === 'undefined'
    ? (() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { setupServer } = require('msw/node');
        const requestInterceptor = setupServer();

        // Load our build time request handlers to set up mocks for requests
        // from getStaticProps functions, but only while building.
        requestInterceptor.use(
          ...require('../../e2e/requestHandlers/defaultRequestHandlers')
        );

        requestInterceptor.listen({
          // silence warnings when actual requests are made
          // https://github.com/mswjs/msw/issues/191#issuecomment-652292341
          onUnhandledRequest: 'bypass',
        });

        return requestInterceptor;
      })()
    : undefined;

type PageProps = {
  dehydratedState?: DehydratedState;
  translation?: Messages;
};

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  /**
   * Set absolute URL's for dynamic content types ensuring there is a crawlable
   * definitive URL associated with that content type.
   * Localised content delivered in the same language can be considered duplicate
   * content, so we always create a canonical without the locale (i.e. setting en-US
   * as the source of truth).
   */
  if (!process.env.NEXT_PUBLIC_SITE_URL || !process.env.NEXT_PUBLIC_BV_URL) {
    throw new Error('Required environment variables are missing');
  }
  const storeUrl: string | undefined = process.env.NEXT_PUBLIC_SITE_URL;
  const router = useRouter();
  const locale = router.locale;
  const storeLocale = getStoreLocale(locale);

  const { seoPath, seoPathAlt, siteName, ogType } = getSeoData(
    storeUrl,
    router
  );

  useEffect(() => {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_KEY,
      useCookie: true,
    });
  }, []);

  // Mikmak configuration
  const mikMakEnabledLocales = ['fr-FR', 'es-ES'];
  const isMikmakEnabled = locale
    ? mikMakEnabledLocales.includes(locale)
    : false;

  // BazaarVoice configuration
  const shouldLoadBvScript = localesWithBazaarVoice.includes(storeLocale);
  const bvScriptUrl = `${process.env.NEXT_PUBLIC_BV_URL}/${getLocaleFormatted(
    locale as string
  )}`;

  return (
    <>
      {process.env.NODE_ENV === 'development' &&
        process.env.NEXT_PUBLIC_XSTATE_DEBUG === 'true' && <XstateDebug />}
      <AppProviders
        dehydratedState={pageProps.dehydratedState}
        translation={pageProps.translation}
      >
        <DefaultSeo
          canonical={storeLocale === 'en-US' ? seoPath : seoPathAlt}
          openGraph={{
            type: ogType,
            locale,
            url: seoPath,
            siteName,
          }}
          twitter={{
            cardType: 'summary',
          }}
          languageAlternates={[
            ...LOCALES.map((locale) => {
              if (locale === 'en-US') {
                return {
                  hrefLang: 'en-US',
                  href: seoPath,
                };
              }

              return {
                hrefLang: locale,
                href: `${process.env.NEXT_PUBLIC_SITE_URL}/${locale}`,
              };
            }),
            {
              hrefLang: 'x-default',
              href: seoPathAlt,
            },
          ]}
        />
        <Head>
          <link rel="icon" href="/favicon.ico" />
          <link
            rel="preload"
            href="/fonts/SharpGroteskBook-20.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/SharpGroteskLight-20.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/ProximaNova-Thin.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/ProximaNova-Regular.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/ProximaNova-Bold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/ABCArizonaMix-Light.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
          <link
            rel="preload"
            href="/fonts/ABCArizonaMix-LightItalic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />
        </Head>
        {shouldLoadBvScript && <Script async src={`${bvScriptUrl}/bv.js`} />}
        {isMikmakEnabled && <Swaven />}
        <MainLayout>
          <Component {...pageProps} />
          <SignUpPopup />
        </MainLayout>
      </AppProviders>
    </>
  );
}
export default MyApp;
