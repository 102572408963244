/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */

/**
 * Fetch contenful config string from enviroment variables
 */
const contenfulSpace =
  process.env.CONTENTFUL_SPACE ??
  process.env.NEXT_PUBLIC_CONTENTFUL_SPACE ??
  '';
const contentfulBranch =
  process.env.CONTENTFUL_BRANCH ??
  process.env.NEXT_PUBLIC_CONTENTFUL_BRANCH ??
  '';
const graphcdn =
  process.env.CONTENTFUL_CDN ?? process.env.NEXT_PUBLIC_CONTENTFUL_CDN ?? '';
const previewToken =
  process.env.CONTENTFUL_PREVIEW_TOKEN ??
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN ??
  '';

/**
 * Contenful Query Data Source
 *
 * Retrieve a valid data source for the GraphQL fetcher based
 * on config arguments.
 *
 * For contenful these areguments are primarily based on the type of
 * query, whether it is a preview or to the 'production' CDN URL
 *
 * @param param0: {
    byPassCache = false,
    space,
    branch,
  }
 * @returns: {
    byPassCache?: boolean;
    space?: string;
    branch?: string;
  }
 */
export const contentfulQueryDataSource = ({
  byPassCache = false,
  space,
  branch,
}: {
  byPassCache?: boolean;
  space?: string;
  branch?: string;
}): {
  endpoint: string;
  fetchParams?: RequestInit;
} => {
  /**
   * Construct contenful connection string based on passed variables
   * if a branch and space have been set these are added here
   */
  const contenful = `https://graphql.contentful.com/content/v1/spaces/${
    space ?? contenfulSpace
  }/environments/${branch ?? contentfulBranch}`;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  /**
   * If byPassCache has been set the preview token is used
   * if not the CDN's injected token will be assumed to be in use
   */
  if (byPassCache) {
    headers.authorization = `Bearer ${previewToken}`;
  }

  const endpoint = byPassCache ? contenful : graphcdn;

  return {
    /**
     * If byPassCache is true use the direct contenful conection,
     * otherwise use the production CDN
     */
    endpoint,
    fetchParams: {
      headers,
      method: 'GET',
    },
  };
};
