export function arrayPushPosition<T>(
  array: Array<T>,
  element: T,
  index: number
): Array<T> {
  return [
    ...array.slice(0, index),
    element,
    ...array.slice(index + 1, array.length),
  ];
}
