import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { storeLocale } from '@store-constants';
import { useGetChCollectionHandlesQuery } from 'lib/contentful/__generated__/ChCollectionHandles';
import type { CollectionQueryVariables } from 'lib/contentful/__generated__/Collection';
import { useCollectionQuery } from 'lib/contentful/__generated__/Collection';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { useGetDeepestHandle } from 'utils/hooks/useGetDeepestHandle';

export const useCollectionLocaleSwitcher = () => {
  const router = useRouter();
  const contentfulHandle = useGetDeepestHandle();
  const preview =
    typeof router.query.preview === 'string' ? !!router.query.preview : false;
  const locale = storeLocale(router.locale || router.defaultLocale);

  const contentfulCollectionQueryVariables: CollectionQueryVariables = {
    handle: contentfulHandle,
    locale,
    preview,
  };

  const { data: collectionContentfulId } = useQuery(
    useCollectionQuery.getKey(contentfulCollectionQueryVariables),
    useCollectionQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: preview }),
      contentfulCollectionQueryVariables
    ),
    {
      select: (data) => {
        return data.collection?.items[0]?.sys.id;
      },
    }
  );

  const { data: handleByLanguage } = useGetChCollectionHandlesQuery(
    contentfulQueryDataSource({ byPassCache: preview }),
    {
      id: collectionContentfulId || '',
      preview,
    },
    {
      enabled: !!collectionContentfulId,
      select: (data) => {
        return Object.entries(data).reduce((acc, [key, val]) => {
          acc[key] = (val as { handle?: string })?.handle || router.asPath;
          return acc;
        }, {} as Record<string, string>);
      },
    }
  );

  return handleByLanguage || router.asPath;
};
