import { set } from 'idb-keyval';
import { assign } from 'xstate';
import { keyvalStore } from 'components/storeContext/idbStore';
import type { StoreContext, StoreEvents } from '../storeMachine';

export const assignCartId = assign(
  (context: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (
      event.type !== 'CART_UPDATED' &&
      event.type !== 'CART_ATTRIBUTES_UPDATED'
    )
      return {};

    return { cartId: { [context.store]: event.payload.cartId } };
  }
);

export const assignCart = assign(
  (context: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (
      event.type !== 'CART_UPDATED' &&
      event.type !== 'CART_ATTRIBUTES_UPDATED' &&
      event.type !== 'CART_DISCOUNT_CHECK' &&
      event.type !== 'CART_GIFTS_CHECK'
    )
      return {};

    return { cart: { [context.store]: event.payload.cart } };
  }
);

export const assignCartErrors = assign<StoreContext, StoreEvents>(
  (_: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (event.type !== 'CART_ERROR') return {};

    return { cartErrors: event.payload };
  }
);

export const setCartId = async (context: StoreContext, event: StoreEvents) => {
  if (event.type !== 'CART_UPDATED' && event.type !== 'CART_ATTRIBUTES_UPDATED')
    return;
  await set(`cartId_${context.store}`, event.payload.cartId, keyvalStore);
};

export const setCart = async (context: StoreContext, event: StoreEvents) => {
  if (
    event.type !== 'CART_UPDATED' &&
    event.type !== 'CART_ATTRIBUTES_UPDATED' &&
    event.type !== 'CART_DISCOUNT_CHECK' &&
    event.type !== 'CART_GIFTS_CHECK'
  )
    return;

  await set(`cart_${context.store}`, event.payload.cart, keyvalStore);
};

export const assignCartLocale = assign<StoreContext, StoreEvents>(
  (_: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (event.type !== 'LOCALE_UPDATE') return {};

    return { locale: event.payload.locale, store: event.payload.store };
  }
);
