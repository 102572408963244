import { useSelector } from '@xstate/react';
import type { ReactNode } from 'react';
import React from 'react';
import { createContext, useContext } from 'use-context-selector';
import type { StoreFeatureFlags } from '@/root/constants';
import type { StoreState } from 'components/storeContext/StoreContext';
import { StoreContext } from 'components/storeContext/StoreContext';

type featureFlagsContextType = {
  featureFlags: StoreFeatureFlags;
};

export const FeatureFlagsContext = createContext<
  featureFlagsContextType | undefined
>(undefined);

const selectFeatureFlags = (state: StoreState) => state.context.featureFlags;

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const { storeService } = useContext(StoreContext);

  const featureFlags = useSelector(storeService, selectFeatureFlags);

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider'
    );
  }
  return context;
};
