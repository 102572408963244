/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const Swaven = () => {
  const { pathname } = useRouter();

  useEffect(() => {
    const head = document.head;
    const scriptId = 'swaven';

    (window as any).swnTagLoaded = false;

    try {
      const appId = process.env.NEXT_PUBLIC_SWAVEN_ID;
      const appDnt = false;

      const swnDataLayer = ((window as any).swnDataLayer =
        (window as any).swnDataLayer || {});
      swnDataLayer.appId = appId;
      swnDataLayer.eventBuffer ||= [];
      swnDataLayer.loadBuffer ||= [];

      const callback: (typeof swnDataLayer)['widget_callback'] = function (
        e: Record<string, unknown>
      ) {
        if (e.action === 'buy') {
          (window as any).dataLayer.push({
            event: 'mikmak_redirect',
            retailer: (e.retailer as Record<string, string>).name,
          });
        }
      };

      swnDataLayer.widget_callback = callback;

      swnDataLayer.push ||= function (e: unknown) {
        swnDataLayer.eventBuffer.push(e);
      };

      swnDataLayer.load ||= function (e: unknown) {
        swnDataLayer.loadBuffer.push(e);
      };

      swnDataLayer.dnt ||= appDnt;

      const script = document.createElement('script');
      script.async = true;
      script.src =
        '//wtb-tag.mikmak.ai/scripts/' + swnDataLayer.appId + '/tag.min.js';
      script.id = scriptId;

      head.appendChild(script);
    } catch {}

    return () => {
      try {
        const script = document.querySelector('#' + scriptId);
        script && head.removeChild(script);
      } catch {}
    };
  }, [pathname]);

  return null;
};
