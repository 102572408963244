import { assign } from 'xstate';
import type { StoreContext, StoreEvents } from '../storeMachine';

export const assignCustomer = assign(
  (context: StoreContext, event: StoreEvents) => {
    if (
      event.type !== 'CUSTOMER_FOUND' &&
      event.type !== 'CUSTOMER_CREATED' &&
      event.type !== 'CUSTOMER_RESTORE'
    )
      return {};

    if (!event.payload.customer) return {};

    return {
      customerId: { [context.store]: event.payload.customer.id },
      customer: { [context.store]: event.payload.customer },
    };
  }
);

export const assignCustomerBlank = assign(
  (_: StoreContext, _event: StoreEvents): Partial<StoreContext> => {
    return {
      customer: null,
      customerAccessToken: null,
      customerId: null,
    };
  }
);

export const assignCustomerError = assign(
  (context: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (
      event.type !== 'CUSTOMER_SIGN_IN_ERROR' &&
      event.type !== 'CUSTOMER_ERROR'
    )
      return {};

    return {
      customerErrors: {
        ...context.customerErrors,
        [event.type]: event.payload.errors,
      },
    };
  }
);

export const assignCustomerToken = assign(
  (context: StoreContext, event: StoreEvents): Partial<StoreContext> => {
    if (
      event.type !== 'CUSTOMER_TOKEN' &&
      event.type !== 'CUSTOMER_CREATED' &&
      event.type !== 'CUSTOMER_RESTORE'
    )
      return {};

    return {
      customerAccessToken: {
        [context.store]: event.payload.customerAccessToken,
      },
    };
  }
);

export const assignCustomerRestored = assign(
  (_, event: StoreEvents): Partial<StoreContext> => {
    if (event.type !== 'CUSTOMER_TOKEN') return {};

    return {
      customerRestored: true,
    };
  }
);

export const setCustomerClear = async (_: StoreContext, event: StoreEvents) => {
  if (event.type !== 'CUSTOMER_SIGN_OUT') return;

  /* Removed set methods from clientside */
  return null;
};

export const setCustomerToken = async (_: StoreContext, event: StoreEvents) => {
  if (event.type !== 'CUSTOMER_TOKEN' && event.type !== 'CUSTOMER_CREATED')
    return;

  /* Removed set methods from clientside */
  return null;
};

export const setCustomer = async (_: StoreContext, event: StoreEvents) => {
  if (event.type !== 'CUSTOMER_FOUND' && event.type !== 'CUSTOMER_CREATED')
    return;

  /* Removed set methods from clientside */
  return null;
};
