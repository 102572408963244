/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */

import type { StoreLocale, Stores } from '@/root/constants';
import {
  DEFAULT_LOCALE,
  localeToStore,
  storeToAccessToken,
} from '@/root/constants';

const shopifyVersion =
  process.env.STOREFRONT_RELEASE ??
  process.env.NEXT_PUBLIC_STOREFRONT_RELEASE ??
  'unstable';

export const storefrontMutationDataSource = (
  locale: StoreLocale = DEFAULT_LOCALE
): {
  endpoint: string;
  fetchParams: RequestInit;
} => {
  const store = localeToStore(locale);
  const accessToken = storeToAccessToken(store as Stores);

  return {
    endpoint: `https://${store}.myshopify.com/api/${shopifyVersion}/graphql.json`,
    fetchParams: {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  };
};

export const storefrontQueryDataSource = (
  locale: StoreLocale = DEFAULT_LOCALE,
  buyerIp?: string
): {
  endpoint: string;
  fetchParams: RequestInit;
} => {
  const graphCdnBaseUrl = process.env.NEXT_PUBLIC_GRAPH_CDN_BASE_URL ?? '';
  const endpoint = graphCdnBaseUrl.replace('XXX', localeToStore(locale));

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (buyerIp) headers['Shopify-Storefront-Buyer-IP'] = buyerIp;

  /**
   * Set this boolean to `true` to bypass Stellate/GraphCDN caching and make
   * requests direct to Shopify.
   */
  const byPassCache = false;

  if (byPassCache) {
    const store = localeToStore(locale);
    const endpoint = `https://${store}.myshopify.com/api/${shopifyVersion}/graphql.json`;

    const accessToken = storeToAccessToken(store);
    headers['X-Shopify-Storefront-Access-Token'] = accessToken;

    return {
      endpoint,
      fetchParams: {
        headers,
        method: 'POST',
      },
    };
  }

  return {
    endpoint,
    fetchParams: {
      headers,
      method: 'POST',
    },
  };
};

export const storefrontQueryDataSourceUncached = (
  locale: StoreLocale = DEFAULT_LOCALE,
  buyerIp?: string
): {
  endpoint: string;
  fetchParams: RequestInit;
} => {
  const store = localeToStore(locale);
  const accessToken = storeToAccessToken(store);

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': accessToken,
  };

  if (buyerIp) headers['Shopify-Storefront-Buyer-IP'] = buyerIp;

  return {
    endpoint: `https://${store}.myshopify.com/api/${shopifyVersion}/graphql.json`,
    fetchParams: {
      headers,
    },
  };
};
