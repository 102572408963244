import type { StoreCart } from 'components/storeContext/storeMachine';
import type { GetDiscountCodesQuery } from 'lib/shopify-admin/__generated__/GetDiscountCodes';

const DISCOUNT_SEPARATOR = '-';
export const INVALID_DISCOUNT_CODE = 'INVALID_CODE';

export type DiscountCode = {
  code: string;
  sapId: string;
};

export interface DiscountResponse {
  discountCode?: DiscountCode;
  error?: string;
}

export function hasDiscountCode(discountCode: string | string[]) {
  if (Array.isArray(discountCode)) {
    return !!discountCode.length;
  }

  return !!discountCode;
}

export function extractDiscountCodeFromCart(cart: StoreCart) {
  const dCode = cart.discountCodes.at(0)?.code;

  if (!dCode) {
    return null;
  }

  return dCode;
}

export function transformDiscountCodes(
  node: GetDiscountCodesQuery['discountNodes']['nodes'][number]
): DiscountCode[] {
  switch (node.discount.__typename) {
    case 'DiscountCodeApp':
    case 'DiscountCodeBasic':
    case 'DiscountCodeBxgy':
    case 'DiscountCodeFreeShipping':
      return node.discount.codes.nodes.map((discount) =>
        splitDiscountCode(discount.code)
      );

    case 'DiscountAutomaticApp':
    case 'DiscountAutomaticBxgy':
    case 'DiscountAutomaticBasic':
    default:
      // Automatic discount don't have codes
      return [];
  }
}

export function matchDiscountCode(
  discountCode: string,
  discountCodes: DiscountCode[]
) {
  return discountCodes.find(
    (dCode) => dCode.code.toLowerCase() === discountCode.toLowerCase()
  );
}

export function splitDiscountCode(discountCode: string): DiscountCode {
  const [code, sapId] = discountCode.split(DISCOUNT_SEPARATOR);
  return { code, sapId };
}

export function composeDiscountCode(discountCode: DiscountCode): string {
  return `${discountCode.code}${DISCOUNT_SEPARATOR}${discountCode.sapId}`;
}

export function validateDiscountCode(discountCode: string): boolean {
  const splitted = splitDiscountCode(discountCode);
  return !!splitted.code && !!splitted.sapId;
}

export async function getDiscountCode(locale: string, dCode: string) {
  const res = await fetch('/api/discount', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      locale,
      discountCode: dCode,
    }),
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch discount code. Status: ${res.status}`);
  }

  const { discountCode, error }: DiscountResponse = await res.json();

  if (error || !discountCode) {
    throw new Error(error || 'Code not found');
  }

  return composeDiscountCode(discountCode);
}
