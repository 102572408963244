import { t, Trans } from '@lingui/macro';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { useEffectOnceWhen } from 'rooks';
import { useContext } from 'use-context-selector';
import { SiteHeader } from 'components/siteHeader/SiteHeader';
import { sendDataToGTM } from 'utils/gtm/gtm';
import { useGetPageType } from 'utils/hooks/useGetPageType';
import { CustomerContext } from '../customerContext/CustomerContext';

const SiteFooter = dynamic(() => import('components/siteFooter/SiteFooter'));

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { customer, customerLoading } = useContext(CustomerContext);

  const pageType = useGetPageType();

  const gtmTimeout = useRef<NodeJS.Timeout>();

  const sendPageViewGTMData = () => {
    let userType: string;

    if (customer && customer.pro) {
      userType = 'Professional';
    } else if (customer && !customer.pro) {
      userType = 'Consumer';
    } else {
      userType = 'Anonymous';
    }

    gtmTimeout.current = setTimeout(() => {
      sendDataToGTM({
        event: 'page_viewed',
        UserType: userType,
        PageType: pageType,
        PageTitle: document.title,
        PagePath: router.asPath,
      });
    }, 300);
  };

  const asPathNoParams = router.asPath.split('?')[0];

  /**
   * Fire GTM event on router path change.
   * - Uses `asPathNoParams` to avoid sending events just when URL params change
   * - Does not send if `customerLoading` (like when page initially loaded) to
   *   ensure that we know the customer's state
   */
  useEffect(() => {
    if (customerLoading) return;
    sendPageViewGTMData();

    return () => {
      if (gtmTimeout.current) {
        clearTimeout(gtmTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPathNoParams]);

  /**
   * Fire GTM Page View Event once when `customerLoading` is `false`
   * - Handles initial page load situation where `customerLoading` is true and
   *   we don't yet know the customer's status
   * - Once customer loading is finished (set to `false`) we fire this callback
   *   once to save that data to the datalayer
   */
  useEffectOnceWhen(() => {
    sendPageViewGTMData();
  }, !customerLoading);

  useEffect(() => {
    const removeListener = () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };

    const onRouteChangeComplete = () => {
      const previousElement = document.querySelector('#skip-main-link')
        ?.previousElementSibling as HTMLElement;
      previousElement.focus();
      previousElement.blur();
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return removeListener;
  }, []);

  return (
    <>
      <div tabIndex={-1} />
      <a
        aria-label={t({
          id: 'accessibility.skipToMainContent',
          message: 'Skip to main content',
        })}
        id="skip-main-link"
        className="skip-main"
        href="#main"
      >
        <Trans id="accessibility.skipToMainContent">Skip to main content</Trans>
      </a>
      <div className={'site-grid '}>
        <header className={'site-header-section'}>
          <SiteHeader />
        </header>
        <main id="main" className={'site-main-section'}>
          {children}
        </main>
        <footer className={'site-footer-section'}>
          <SiteFooter />
        </footer>
      </div>
    </>
  );
};

export default MainLayout;
