import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types';
import { useQuery } from '@tanstack/react-query';
import type { ImageType } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import type { GetFormPageQuery } from 'lib/contentful/__generated__/FormPage';
import { useGetFormPageQuery } from 'lib/contentful/__generated__/FormPage';
import type { ModuleBannerFragment } from 'lib/contentful/__generated__/ModuleBanner';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { getContentfulTagFilter } from 'utils/contentfulHelpers';
import type { StoreSeo } from 'utils/types/storeTypes';

export type FormPageData = {
  seo?: StoreSeo;
  title: string;
  termsAndConditionsUrl: string;
  copy?: ReactNode;
  image?: ImageType;
  toAddress?: string;
  banner?: ModuleBannerFragment;
};

export type FormPageContext = FormPageData & {
  isLoading: boolean;
};

export const FormPageContext = createContext<FormPageContext>({
  isLoading: false,
  title: '',
  termsAndConditionsUrl: '',
  copy: undefined,
  image: undefined,
  toAddress: '',
});

export function FormPageProvider({
  handle,
  children,
}: {
  handle: string;
  children: ReactNode;
}) {
  const { locale: routerLocale, query } = useRouter();
  const locale = storeLocale(routerLocale);

  const preview = typeof query.preview === 'string' ? !!query.preview : false;

  const variables = {
    locale,
    handle,
    preview,
    tagFilter: getContentfulTagFilter(locale),
  };

  const { data, isLoading } = useQuery(
    useGetFormPageQuery.getKey(variables),
    useGetFormPageQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: true }),
      variables
    ),
    {
      select: getCustomerSupportSelect,
    }
  );

  const contextValue = useMemo(() => {
    if (data) {
      return {
        ...data,
        isLoading,
      };
    }

    return {
      isLoading,
      seo: undefined,
      title: '',
      termsAndConditionsUrl: '',
      toAddress: '',
      image: undefined,
    };
  }, [data, isLoading]);

  return (
    <FormPageContext.Provider value={contextValue}>
      {children}
    </FormPageContext.Provider>
  );
}
export const getFormPagePopupData = (
  originalData: GetFormPageQuery | undefined
): FormPageData | null =>
  originalData?.formPage?.data.length
    ? getCustomerSupportSelect(originalData)
    : null;

export const getCustomerSupportSelect = (
  originalData: GetFormPageQuery
): FormPageData | null => {
  if (!originalData) {
    return null;
  }

  const seoTitle = originalData.formPage?.data[0]?.seoTitle;
  const seoDescription = originalData.formPage?.data[0]?.seoDescription;
  const title = originalData.formPage?.data[0]?.title || '';
  const termsAndConditionsUrl =
    originalData.formPage?.data[0]?.termsAndConditionsUrl || '';
  const toAddress = originalData.formPage?.data[0]?.toAddress || '';

  const jsonData = (originalData.formPage?.data[0]?.copy?.json ??
    '') as Document;

  const copy = documentToReactComponents(jsonData, {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  });

  const seo = {
    title: seoTitle || '',
    description: seoDescription || '',
  };

  const image = {
    height: originalData.formPage?.data[0]?.image?.height ?? 0,
    width: originalData.formPage?.data[0]?.image?.width ?? 0,
    url: originalData.formPage?.data[0]?.image?.url ?? '',
    title: originalData.formPage?.data[0]?.image?.title ?? '',
  };

  return {
    seo,
    title,
    termsAndConditionsUrl,
    copy,
    image,
    toAddress,
    banner: originalData.formPage?.data[0]?.banner || undefined,
  };
};
