import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useCallback, useState, useMemo } from 'react';
import { createContext, useContext } from 'use-context-selector';
import type { StoreLocale } from '@/root/constants';
import type {
  UpdateCustomerWishlistApiPayload,
  WishlistEventType,
} from 'backend/utils/wishlist';
import { useCustomerWishlistQuery } from 'lib/shopify-storefront/__generated__/CustomerWishlist';
import { storefrontQueryDataSourceUncached } from 'lib/shopify-storefront/dataSources';
import { formatWishlistData } from 'utils/format/shopify/formatWishlist';
import { CustomerContext } from '../customerContext/CustomerContext';

export type WishlistContextType = {
  list: {
    products?: string[];
    nailArt?: string[];
    articles?: string[];
  };
  loading: boolean;
  updating: boolean;
  updatingPayload: WishlistMutationType | null;
  add(payload: WishlistItemPayload): void;
  remove(payload: WishlistItemPayload): void;
  clear(payload: WishlistItemPayload): void;
  refresh(): void;
};

/**
 * Wishlist Item Payload
 * - Payload that is generated by the front-end code
 * - Does not need to include `locale` as that is added by the context helpers
 */
type WishlistItemPayload = Omit<UpdateCustomerWishlistApiPayload, 'locale'>;

/**
 * Mutation function arguments type
 * - Sets the types for the mutation functions for Wishlist
 */
type WishlistMutationType = {
  /**
   * Full payload for wishlist API including:
   * - `locale`: Store locale to indicate which store to make the request to
   * - `item`: handle of the item to add or remove from the wishlist
   * - `itemType`: `products`, `nailArt` or `articles`
   */
  payload: UpdateCustomerWishlistApiPayload;
  /**
   * Defines the event type for the mutation: e.g. `add`, `remove` or `clear`
   */
  eventType: WishlistEventType;
};

export const WishlistContext = createContext<WishlistContextType>({
  list: {},
  loading: false,
  updating: false,
  updatingPayload: null,
  add: () => {
    return;
  },
  remove: () => {
    return;
  },
  clear: () => {
    return;
  },
  refresh: () => {
    return;
  },
});

export const WishlistProvider = ({
  children,
  locale,
}: {
  children: ReactNode;
  locale: StoreLocale;
}) => {
  const queryClient = useQueryClient();

  const { customerId, customerAccessToken = '' } = useContext(CustomerContext);

  const customerIdNumber = customerId?.split('/').pop() ?? '';

  const [updatingPayload, setUpdatingPayload] =
    useState<WishlistMutationType | null>(null);

  /**
   * Calculate if the wishlist functionality should be enabled.
   * - Wishlist is only available to logged in customers
   * - Boolean is used to enable/disable the main fetch query.
   */
  const isWishlistEnabled = !!customerAccessToken;

  /**
   * Get customer wishlist
   * - Fires standalone query to get just the customer wishlist.
   * - Uses standalone query so that we can trigger re-fetches without needing
   *   to refetch the whole customer object
   */
  const wishlistQueryVariables = {
    customerAccessToken,
  };

  const wishlistQueryKey = [
    ...useCustomerWishlistQuery.getKey(wishlistQueryVariables),
    locale,
  ];

  const { data, isLoading } = useQuery(
    wishlistQueryKey,
    useCustomerWishlistQuery.fetcher(
      storefrontQueryDataSourceUncached(locale),
      wishlistQueryVariables
    ),
    {
      select(data) {
        if (!data.customer) {
          return null;
        }
        return formatWishlistData(data.customer);
      },
      enabled: isWishlistEnabled,
      refetchOnWindowFocus: true,
    }
  );

  const wishlistMutation = useMutation({
    mutationFn: (data: WishlistMutationType) => {
      return fetch(`/api/wishlist/${customerIdNumber}/${data.eventType}`, {
        method: 'POST',
        body: JSON.stringify(data.payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error updating wishlist');
          }

          return response.json();
        })
        .catch((error) => {
          /* Silently catch errors and log them to the console */
          console.error(error.message);
        });
    },
    onMutate: (data) => {
      setUpdatingPayload(data);
    },
    useErrorBoundary: false,
    onSuccess: () => {
      /** Refetch the main wishlist query on successful mutation */
      queryClient.invalidateQueries({ queryKey: wishlistQueryKey });
    },
    onSettled: () => {
      setUpdatingPayload(null);
    },
  });

  const { mutateAsync, isLoading: mutationLoading } = wishlistMutation;

  const addToWishlist = useCallback(
    async (payload: WishlistItemPayload) => {
      await mutateAsync({
        eventType: 'add',
        payload: {
          locale,
          ...payload,
        },
      });
    },
    [mutateAsync, locale]
  );

  const removeFromWishlist = useCallback(
    async (payload: WishlistItemPayload) => {
      await mutateAsync({
        eventType: 'remove',
        payload: {
          locale,
          ...payload,
        },
      });
    },
    [mutateAsync, locale]
  );

  const clearWishlist = useCallback(
    async (payload: WishlistItemPayload) => {
      await mutateAsync({
        eventType: 'clear',
        payload: {
          locale,
          ...payload,
        },
      });
    },
    [mutateAsync, locale]
  );

  const refreshWishlist = () => {
    queryClient.invalidateQueries({ queryKey: wishlistQueryKey });
  };

  const wishlistUpdating = isWishlistEnabled && (isLoading || mutationLoading);

  const contextValue: WishlistContextType = useMemo(() => {
    return {
      list: data ? data : {},
      loading: isLoading,
      updating: wishlistUpdating,
      updatingPayload,
      add: addToWishlist,
      remove: removeFromWishlist,
      clear: clearWishlist,
      refresh: refreshWishlist,
    };
  }, [
    data,
    isLoading,
    wishlistUpdating,
    updatingPayload,
    clearWishlist,
    addToWishlist,
    removeFromWishlist,
    refreshWishlist,
  ]);

  return (
    <WishlistContext.Provider value={contextValue}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  return useContext(WishlistContext);
};
