/* eslint-disable @typescript-eslint/naming-convention */
import type { Sender } from 'xstate';
import { storeLocale } from '@/root/constants';
import type { CustomerResponse } from 'pages/api/customer';
import type { ActivateResponse } from 'pages/api/customer/activate';
import type { CreateResponse } from 'pages/api/customer/create';
import type { LoginResponse } from 'pages/api/customer/login';
import type { RecoverPasswordResponse } from 'pages/api/customer/recover';
import type { CustomerRenewResponse } from 'pages/api/customer/renew';
import type { CustomerRestoreResponse } from 'pages/api/customer/restore';
import { type StoreContext, type StoreEvents } from '../storeMachine';

const defaultHeaders = {
  'content-type': 'application/json;charset=UTF-8',
};

const customerLogin = async ({
  event,
  locale,
}: {
  event: StoreEvents;
  locale: string;
}) => {
  if (event.type !== 'CUSTOMER_SIGN_IN' && event.type !== 'CUSTOMER_SIGN_UP')
    return null;

  /* Call to local API route to fetch access token and set session token */
  const response = await window.fetch(
    `${window.location.origin}/api/customer/login`,
    {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({
        email: event.payload.email,
        password: event.payload.password,
        locale,
      }),
    }
  );

  const { data, errors }: LoginResponse = await response.json();

  if (data) {
    return { data };
  }

  return { error: errors };
};

export const customerCreate =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_SIGN_UP') return;

    /**
     * Maps the payload into a `FormData` object.
     * - Adds further data, such as the store locale.
     */
    const form = new FormData();

    Object.entries(event.payload).forEach(([key, value]) => {
      if (value === null) {
        return;
      }

      if (key === 'files') {
        value.forEach((file: File, index: number) => {
          form.append(`files_${index}`, file, file.name);
        });

        return;
      }

      form.append(key, value);
    });

    form.append('locale', context.locale);

    /* Call to local API route to create customer set session customer */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/create`,
      {
        method: 'POST',
        body: form,
      }
    );

    const { data, errors }: CreateResponse = await response.json();

    if (data?.customer) {
      const customer = data?.customer;

      /* If a customer has been created login that customer to set session token */
      const loginResponse = await customerLogin({
        event: {
          type: 'CUSTOMER_SIGN_IN',
          payload: {
            email: form.get('email') as string,
            password: form.get('password') as string,
          },
        },
        locale: context.locale,
      });

      if (!loginResponse?.data?.accessToken) {
        send('CUSTOMER_ERROR');
        return;
      }

      const customerAccessToken = loginResponse.data.accessToken;

      if (typeof customerAccessToken === 'string') {
        return send({
          type: 'CUSTOMER_CREATED',
          payload: {
            customer,
            customerAccessToken,
          },
        });
      }

      return send('CUSTOMER_ERROR');
    }

    // Otherwise, send CUSTOMER_ERROR event
    return send({
      type: 'CUSTOMER_ERROR',
      payload: {
        errors: errors ?? [],
      },
    });
  };

export const customerInit =
  (context: StoreContext) => async (send: Sender<StoreEvents>) => {
    /* Call to local API route to fetch access token and customer */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/restore`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data }: CustomerRestoreResponse = await response.json();

    if (response.ok && data) {
      const customer = { ...data, accessToken: undefined };
      return send({
        type: 'CUSTOMER_RESTORE',
        payload: {
          customerAccessToken: data.accessToken ?? '',
          customer,
        },
      });
    } else {
      send('CUSTOMER_IDLE');
    }
  };

export const customerTokenRenew =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_RESTORE') return;

    /* Call to local API route to renew access token */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/renew`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data }: CustomerRenewResponse = await response.json();

    if (response.ok && data) {
      return send({
        type: 'CUSTOMER_TOKEN',
        payload: {
          customerAccessToken: data.accessToken ?? '',
        },
      });
    } else {
      send('CUSTOMER_ERROR');
    }
  };

export const customerFetch =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_TOKEN') return;

    const customerAccessToken = event.payload.customerAccessToken ?? '';
    /* Call to local API route to fetch access token and set session token */
    const response = await window.fetch(
      `${window.location.origin}/api/customer`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          locale: storeLocale(context.locale),
          customerAccessToken,
        }),
      }
    );

    const { data }: CustomerResponse = await response.json();

    if (response.ok && data) {
      const customer = { ...data, accessToken: undefined };
      return send({
        type: 'CUSTOMER_FOUND',
        payload: {
          customer,
          // if the customer is a pro, reset the pro cart on login so he won't be able to checkout with the previous cart
          resetProCart: event.payload.resetProCart && data.pro,
        },
      });
    } else {
      send('CUSTOMER_ERROR');
    }
  };

export const customerSignIn =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_SIGN_IN') return null;

    // Call sign-in API route to set session token
    const loginResponse = await customerLogin({
      event,
      locale: storeLocale(context.locale),
    });

    if (!loginResponse?.data?.accessToken)
      return send({
        type: 'CUSTOMER_SIGN_IN_ERROR',
        payload: {
          errors: loginResponse?.error ?? [],
        },
      });

    const customerAccessToken = loginResponse.data.accessToken;

    return send({
      type: 'CUSTOMER_TOKEN',
      payload: {
        customerAccessToken,
        resetProCart: true, // send flag to reset pro cart on login (will be validated in the customerFetch service and reset the cart if the customer is a pro)
      },
    });
  };

export const customerSignOut =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_SIGN_OUT') {
      return null;
    }

    /* Call to local API route to log out and remove customer session */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/logout`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data } = await response.json();

    if (!data || !data.ok) {
      return send({
        type: 'CUSTOMER_SIGNED_OUT_ERROR',
      });
    }

    return send({
      type: 'CUSTOMER_SIGNED_OUT',
    });
  };

export const customerActivate =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_ACTIVATE') {
      return null;
    }

    /* Call to local API route to activate customer */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/activate`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          activationUrl: event.payload.activationUrl,
          password: event.payload.password,
          captcha: event.payload.captcha,
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data, errors }: ActivateResponse = await response.json();

    // Check response is ok, if so send the CUSTOMER_TOKEN event.
    if (response.ok && data?.accessToken) {
      return send({
        type: 'CUSTOMER_TOKEN',
        payload: {
          customerAccessToken: data.accessToken,
        },
      });
    }

    // Otherwise, send CUSTOMER_ERROR event
    return send({
      type: 'CUSTOMER_ERROR',
      payload: {
        errors: errors ?? [],
      },
    });
  };

export const customerReset =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_RESET') {
      return null;
    }

    /* Call to local API route to reset customer password */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/reset`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          resetUrl: event.payload.resetUrl,
          password: event.payload.password,
          captcha: event.payload.captcha,
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data, errors }: ActivateResponse = await response.json();

    // Check response is ok, if so send the CUSTOMER_TOKEN event.
    if (response.ok && data?.accessToken) {
      return send({
        type: 'CUSTOMER_TOKEN',
        payload: {
          customerAccessToken: data.accessToken,
        },
      });
    }

    // Otherwise, send CUSTOMER_ERROR event
    return send({
      type: 'CUSTOMER_ERROR',
      payload: {
        errors: errors ?? [],
      },
    });
  };

export const customerRecover =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CUSTOMER_RECOVER') {
      return null;
    }

    /* Call to local API route to reset customer password */
    const response = await window.fetch(
      `${window.location.origin}/api/customer/recover`,
      {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          email: event.payload.email,
          captcha: event.payload.captcha,
          locale: storeLocale(context.locale),
        }),
      }
    );

    const { data, errors }: RecoverPasswordResponse = await response.json();

    // Check response is ok, if so send the CUSTOMER_TOKEN event.
    if (response.ok && data?.success) {
      return send({
        type: 'CUSTOMER_RECOVER_SUCCESS',
      });
    }

    // Otherwise, send CUSTOMER_ERROR event
    return send({
      type: 'CUSTOMER_ERROR',
      payload: {
        errors: errors ?? [],
      },
    });
  };
