/**
 *
 */
const queryCleanner = (str: string): string =>
  str.replace(/(?:[\s,]|#[^\n\r]+)+/g, ' ').trim();

const modifyVariables = (variables?: Record<string, unknown>) => {
  if (variables?.locale === 'jp-JP') {
    variables.locale = 'ja-JP';
  }
};
/**
 *
 */
export function fetcher<TData, TVariables>(
  endpoint: string,
  requestInit: RequestInit,
  query: string,
  variables?: TVariables,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options?: unknown
) {
  return async (): Promise<TData> => {
    modifyVariables(variables as Record<string, unknown>);
    const method = requestInit.method === 'GET' ? 'GET' : 'POST';

    const url =
      method === 'POST'
        ? endpoint
        : `${endpoint}?query=${encodeURIComponent(queryCleanner(query))}${
            variables
              ? `&variables=${encodeURIComponent(JSON.stringify(variables))}`
              : ''
          }`;

    const res = await fetch(url, {
      method,
      ...requestInit,
      body:
        method === 'POST' ? JSON.stringify({ query, variables }) : undefined,
    });

    const json = await res.json();

    if (json.errors) {
      const isContentfulUnresolvedLinkError =
        Array.isArray(json.errors) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        json.errors.every((error: any) => {
          return error?.extensions?.contentful?.code === 'UNRESOLVABLE_LINK';
        });

      if (isContentfulUnresolvedLinkError && json.data) {
        // Reminder: check error logging down the line
        console.error(json.errors[0]);

        return json.data;
      }

      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
