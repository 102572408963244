import { getMany } from 'idb-keyval';
import type { Sender } from 'xstate/lib/types';
import { isChLocale, storeLocale } from '@/root/constants';
import {
  getDiscountCode,
  hasDiscountCode,
  INVALID_DISCOUNT_CODE,
} from 'backend/utils/discount';
import { localeToCountryLanguage } from 'components/route/utils/localeToCountryLanguage';
import { keyvalStore } from 'components/storeContext/idbStore';
import type {
  StoreCartLines,
  StoreContext,
  StoreEvents,
} from 'components/storeContext/storeMachine';
import { getGiftsHandlerMutation } from 'components/storeContext/utils/addGiftsMutation';
import { getCartDiscountHandlerMutation } from 'components/storeContext/utils/cartDiscountHandlerMutation';
import { cleanCart } from 'components/storeContext/utils/dataClean';
import { useCartAttributesUpdateMutation } from 'lib/shopify-storefront/__generated__/CartAttributesUpdateMutation';
import { useCartBuyerIdentityUpdateMutation } from 'lib/shopify-storefront/__generated__/CartBuyerIdentityUpdateMutation';
import { useCartCreateMutation } from 'lib/shopify-storefront/__generated__/CartCreateMutation';
import { useCartDiscountCodesUpdateMutation } from 'lib/shopify-storefront/__generated__/CartDiscountCodesUpdateMutation';
import { useCartLineAddMutation } from 'lib/shopify-storefront/__generated__/CartLineAddMutation';
import { useCartLineRemoveMutation } from 'lib/shopify-storefront/__generated__/CartLineRemoveMutation';
import { useCartLineUpdateMutation } from 'lib/shopify-storefront/__generated__/CartLineUpdateMutation';
import { useCartQuery } from 'lib/shopify-storefront/__generated__/CartQuery';
import { useGetProductQuery } from 'lib/shopify-storefront/__generated__/GetProduct';
import { useGetProductVariantsQuery } from 'lib/shopify-storefront/__generated__/GetProductVariants';
import type {
  AttributeInput,
  CartLineInput,
} from 'lib/shopify-storefront/__generated__/types';
import {
  storefrontMutationDataSource,
  storefrontQueryDataSource,
} from 'lib/shopify-storefront/dataSources';
import { sendDataToGTM } from 'utils/gtm/gtm';
import { getCleanProductHandle } from 'utils/helpers';

export const cartReinit =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    // there is a case when we need to reset the cart for pro users - cart contains non-pro products
    if (event.type === 'CUSTOMER_FOUND' && event.payload?.resetProCart) {
      // this code creates new empty cart
      const locale = storeLocale(context.locale);
      const localeCodes = localeToCountryLanguage(locale);
      useCartCreateMutation
        .fetcher(storefrontMutationDataSource(locale), {
          ...localeCodes,
          input: {
            lines: [],
          },
        })()
        .then((data) => {
          if (!data.cartCreate?.cart) return send('CART_ERROR');

          const cartId = data.cartCreate?.cart?.id ?? '';
          const cart = cleanCart(data.cartCreate?.cart);

          return send({
            type: 'CART_CREATED',
            payload: { cartId, cart },
          });
        })
        .catch((error) => {
          console.warn(error);
          send('CART_ERROR');
        });

      return;
    }

    const lines =
      context.cart?.[context.store].lines ||
      ((await getMany(
        [`cartId_${context.store}`, `cart_${context.store}`],
        keyvalStore
      ).then(([_, cart]) => cart?.lines || [])) as StoreCartLines[]);

    const locale = storeLocale(context.locale);
    const localeCodes = localeToCountryLanguage(locale);

    useCartCreateMutation
      .fetcher(storefrontMutationDataSource(locale), {
        ...localeCodes,
        input: {
          lines: lines.map((line) => ({
            merchandiseId: line.merchandise.id,
            attributes: line.attributes as AttributeInput[],
            quantity: line.quantity,
          })),
        },
      })()
      .then((data) => {
        if (!data.cartCreate?.cart) return send('CART_ERROR');

        const cartId = data.cartCreate?.cart?.id ?? '';
        const cart = cleanCart(data.cartCreate?.cart);

        return send({
          type: 'CART_CREATED',
          payload: { cartId, cart },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

export const cartInit =
  (context: StoreContext) => async (send: Sender<StoreEvents>) => {
    const { products_removed: productsRemoved } = context.query;
    /**
     * Check if user redirected from checkout after
     * removing products.
     * This checkout redirection happens when a consumer
     * has only pro products in cart and proceeds to checkout.
     */
    if (productsRemoved !== undefined) return send('CART_STORAGE_EMPTY');

    if (!context.store) return send('CART_STORAGE_EMPTY');

    getMany([`cartId_${context.store}`, `cart_${context.store}`], keyvalStore)
      .then(async ([cartId, cart]) => {
        if (!cartId || !cart) {
          return send('CART_REINIT');
        }

        /**
         * Check if cart still valid.
         */
        const cartData = await useCartQuery
          .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
            id: cartId,
          })()
          .then((data) => {
            if (!data.cart) throw 'Cart Not Valid!';

            return data.cart;
          });

        const newCart = cleanCart(cartData);

        return send({
          type: 'CART_UPDATED',
          payload: { cart: newCart, cartId },
        });
      })
      .catch((_) => {
        console.log('ERROR');
        send('CART_REINIT');
      });
  };

export const cartDiscountCodesUpdate =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_DISCOUNT_CODES_UPDATE') return;

    let discountCode: string | string[] = [];
    const locale = storeLocale(context.locale);

    if (isChLocale(locale)) {
      if (hasDiscountCode(event.payload.discountCodes)) {
        try {
          discountCode = await getDiscountCode(
            locale,
            Array.isArray(event.payload.discountCodes)
              ? event.payload.discountCodes[0]
              : event.payload.discountCodes
          );
        } catch (e) {
          discountCode = INVALID_DISCOUNT_CODE;
          console.warn(e);
        }
      }
    } else {
      discountCode = event.payload.discountCodes;
    }

    useCartDiscountCodesUpdateMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: event.payload.id,
        discountCodes: discountCode,
      })()
      .then((data) => {
        if (!data?.cartDiscountCodesUpdate?.cart) return send('CART_ERROR');

        const cart = cleanCart(data.cartDiscountCodesUpdate.cart);
        return send({
          type: 'CART_GIFTS_CHECK',
          payload: {
            cartId: data.cartDiscountCodesUpdate.cart.id,
            cart,
          },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

export const cartCreate =
  (context: StoreContext) => async (send: Sender<StoreEvents>) => {
    const locale = storeLocale(context.locale);

    const localeCodes = localeToCountryLanguage(locale);
    useCartCreateMutation
      .fetcher(storefrontMutationDataSource(locale), {
        ...localeCodes,
        input: {},
      })()
      .then((data) => {
        if (!data.cartCreate?.cart) return send('CART_ERROR');

        const cartId = data.cartCreate?.cart?.id ?? '';
        const cart = cleanCart(data.cartCreate?.cart);

        return send({
          type: 'CART_CREATED',
          payload: { cartId, cart },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

export const addCartAttributes =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_CREATED') return;
    const locale = storeLocale(context.locale);
    const localeCodes = localeToCountryLanguage(locale);

    const { cartId } = event.payload;

    useCartAttributesUpdateMutation
      .fetcher(storefrontMutationDataSource(locale), {
        ...localeCodes,
        cartId,
        attributes: [
          {
            key: '__cartId',
            value: cartId,
          },
          {
            key: '__headlessURL',
            value: `${window.location.protocol}//${window.location.host}/${locale}`,
          },
        ],
      })()
      .then((data) => {
        if (!data.cartAttributesUpdate?.cart) return send('CART_ERROR');

        const cartId = data?.cartAttributesUpdate?.cart.id ?? '';
        const cart = cleanCart(data.cartAttributesUpdate?.cart);

        return send({
          type: 'CART_ATTRIBUTES_UPDATED',
          payload: { cartId, cart },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

export const cartLineAdd =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_LINE_ADD') return;

    const payloadLines: CartLineInput[] = event.payload.map((line) => {
      return {
        merchandiseId: line.id,
        quantity: line.quantity,
      };
    });

    useCartLineAddMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        linesToAdd: payloadLines,
      })()
      .then((data) => {
        if (!data.cartLinesAdd?.cart) return send('CART_ERROR');
        const cartId = data.cartLinesAdd?.cart?.id ?? '';
        const cart = cleanCart(data.cartLinesAdd?.cart);

        event.payload.forEach((payloadLine) => {
          const addedProduct = cart.lines.find(
            (line) => line.merchandise.id === payloadLine.id
          );
          sendAddEvent(addedProduct, payloadLine.quantity);
        });

        return send({
          type: 'CART_GIFTS_CHECK',
          payload: {
            cartId,
            cart,
          },
        });
      })
      .catch((error) => handleErrorWithPayload(error, send));
  };

export const cartGiftsHandle =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_GIFTS_CHECK') return;

    const { cart, cartId } = event.payload;
    const giftsHandlerMutation = await getGiftsHandlerMutation(context, cart);

    if (!giftsHandlerMutation) {
      return send({
        type: 'CART_DISCOUNT_CHECK',
        payload: { cartId, cart },
      });
    }

    try {
      if (!giftsHandlerMutation?.cart) return send('CART_ERROR');

      const updatedCartId = giftsHandlerMutation?.cart?.id ?? '';
      const updatedCart = cleanCart(giftsHandlerMutation?.cart);

      return send({
        type: 'CART_DISCOUNT_CHECK',
        payload: { cartId: updatedCartId, cart: updatedCart },
      });
    } catch (error) {
      console.warn(error);
      send('CART_ERROR');
    }
  };

export const cartDiscountCheckHandle =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_DISCOUNT_CHECK') return;

    const { cartId, cart } = event.payload;

    const cartDiscountHandlerMutation = await getCartDiscountHandlerMutation(
      context,
      cart
    );

    if (!cartDiscountHandlerMutation) {
      return send({
        type: 'CART_UPDATED',
        payload: { cartId, cart },
      });
    }

    try {
      if (!cartDiscountHandlerMutation?.cart) return send('CART_ERROR');

      const updatedCartId = cartDiscountHandlerMutation?.cart?.id ?? '';
      const updatedCart = cleanCart(cartDiscountHandlerMutation?.cart);

      return send({
        type: 'CART_UPDATED',
        payload: { cartId: updatedCartId, cart: updatedCart },
      });
    } catch (error) {
      console.warn(error);
      send('CART_ERROR');
    }
  };

export const cartFindVariant =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_QUICK_ADD') return;

    const locale = storeLocale(context.locale);
    const localeCodes = localeToCountryLanguage(locale);

    const normalizedProductId = event.payload.productId.startsWith(
      'gid://shopify/Product/'
    )
      ? event.payload.productId
      : `gid://shopify/Product/${event.payload.productId}`;

    useGetProductVariantsQuery
      .fetcher(storefrontQueryDataSource(locale), {
        ...localeCodes,
        id: normalizedProductId,
      })()
      .then((data) => {
        if (!data.product?.variants.edges.length) return send('CART_ERROR');

        /**
         * Find variant ID
         */
        const id = data.product.variants.edges[0].node.id;

        return send({
          type: 'CART_LINE_ADD',
          payload: [{ id, quantity: 1 }],
        });
      })
      .catch((error) => handleErrorWithPayload(error, send));
  };

export const cartLineRemove =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_LINE_REMOVE') return;

    useCartLineRemoveMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        lineIdsToRemove: event.payload.ids,
      })()
      .then((data) => {
        if (!data.cartLinesRemove?.cart) return send('CART_ERROR');

        const cartBefore = context.cart?.[context.store];

        const ids =
          typeof event.payload.ids === 'string'
            ? [event.payload.ids]
            : event.payload.ids;

        ids.forEach((id: string) => {
          const removedItem = cartBefore?.lines.find((line) => line.id === id);

          if (removedItem) {
            sendRemoveEvent(removedItem, removedItem?.quantity);
          }
        });

        const cartId = data.cartLinesRemove.cart.id ?? '';
        const cart = cleanCart(data.cartLinesRemove.cart);

        return send({
          type: 'CART_GIFTS_CHECK',
          payload: { cartId, cart },
        });
      })
      .catch((error) => handleErrorWithPayload(error, send));
  };

export const cartLineUpdate =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_LINE_UPDATE') return;

    const cartBefore = context.cart?.[context.store];
    const updatedItem = cartBefore?.lines.find(
      (line) => line.id === event.payload.id
    );

    useCartLineUpdateMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        linesToUpdate: [
          {
            id: event.payload.id,
            quantity: event.payload.quantity,
          },
        ],
      })()
      .then((data) => {
        if (!data.cartLinesUpdate?.cart) return send('CART_ERROR');

        const previousQuantity = updatedItem?.quantity || 0;
        const newQuantity = event.payload.quantity;

        if (previousQuantity < newQuantity) {
          sendAddEvent(updatedItem, newQuantity - previousQuantity);
        } else {
          sendRemoveEvent(updatedItem, previousQuantity - newQuantity);
        }

        const cartId = data.cartLinesUpdate.cart.id ?? '';
        const cart = cleanCart(data.cartLinesUpdate.cart);

        return send({
          type: 'CART_GIFTS_CHECK',
          payload: { cartId, cart },
        });
      })
      .catch((error) => handleErrorWithPayload(error, send));
  };

export const cartById =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (event.type !== 'CART_UPDATED' && event.type !== 'CUSTOMER_FOUND')
      return;

    // propagate resetProCart to cartReinit - to create empty cart
    if (event.type === 'CUSTOMER_FOUND' && event.payload.resetProCart) {
      return send({
        type: 'CART_RECREATE',
        payload: { resetProCart: event.payload.resetProCart },
      });
    }

    if (
      event.type === 'CUSTOMER_FOUND' &&
      context.cartId?.[context.store] === event.payload.cartId
    ) {
      return send('CART_FOUND');
    }

    if (!event.payload.cartId) return send('CART_RECREATE');

    useCartQuery
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        id: event.payload.cartId,
      })()
      .then((data) => {
        if (!data.cart) return send('CART_RECREATE');

        const cart = cleanCart(data.cart);
        const cartId = data.cart?.id ?? '';

        return send({
          type: 'CART_UPDATED',
          payload: { cartId, cart },
        });
      });
  };

export const cartIdentityUpdate =
  (context: StoreContext, event: StoreEvents) =>
  async (send: Sender<StoreEvents>) => {
    if (
      (event.type !== 'CUSTOMER_TOKEN' &&
        event.type !== 'CUSTOMER_SIGNED_OUT') ||
      !context.cartId
    )
      return;

    const customerAccessToken =
      event.type === 'CUSTOMER_TOKEN'
        ? event.payload.customerAccessToken
        : null;

    useCartBuyerIdentityUpdateMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        buyerIdentity: {
          customerAccessToken,
        },
      })()
      .then((data) => {
        if (!data.cartBuyerIdentityUpdate?.cart) return send('CART_ERROR');

        const cart = cleanCart(data.cartBuyerIdentityUpdate.cart);
        const cartId = data.cartBuyerIdentityUpdate.cart?.id ?? '';

        return send({
          type: 'CART_UPDATED',
          payload: { cartId, cart },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

export const giftTriggerAfterCustomerStatusChange =
  (context: StoreContext, event: StoreEvents) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async (send: Sender<StoreEvents>) => {
    if (
      (event.type !== 'CUSTOMER_FOUND' &&
        event.type !== 'CUSTOMER_SIGNED_OUT' &&
        event.type !== 'CART_ATTRIBUTES_UPDATED') ||
      !context.cartId
    )
      return;

    const cartId = context.cartId?.[context.store] ?? '';
    const locale = context.locale;
    const mutationDataSource = storefrontMutationDataSource(
      storeLocale(locale)
    );
    const pro = context.customer?.[context.store]?.pro;

    useCartQuery
      .fetcher(mutationDataSource, {
        id: cartId,
      })()
      .then(async (data) => {
        if (!data.cart) {
          return send('CART_RECREATE');
        } else if (locale === 'en-US') {
          return send({
            type: 'CART_GIFTS_CHECK',
            payload: { cartId, cart: cleanCart(data.cart) },
          });
        }

        const lineIdsToRemove = [],
          linesToAdd = [];
        const localeCodes = localeToCountryLanguage(locale);

        // Iterate through cart lines
        for (const line of data.cart.lines.edges) {
          const {
            node: {
              id,
              merchandise: {
                product: { handle },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                meta_pro,
              },
              quantity,
            },
          } = line;
          const targetHandle =
            getCleanProductHandle(handle) + (pro ? '-pro' : '');

          // If the product handle matches the target handle, skip it
          if (handle === targetHandle) {
            continue;
          }

          // If the customer is not a pro user and the product is marked as pro-only,
          // remove it from the cart. Otherwise, attempt to find the corresponding
          // normal or pro version of the product for replacement.
          if (!pro && meta_pro?.value === 'true') {
            lineIdsToRemove.push(id);

            // Fetch product data for replacement
            const { product } = await useGetProductQuery.fetcher(
              storefrontQueryDataSource(locale),
              {
                handle: targetHandle,
                includeReferenceMetafieldDetails: false,
                ...localeCodes,
              }
            )();
            if (product) {
              const currentVariant = product?.variants?.edges.find(
                (v) =>
                  v.node.title === line.node.merchandise.title ||
                  (v.node.sku && v.node.sku === line.node.merchandise.sku)
              )?.node;

              if (
                !!currentVariant &&
                currentVariant.meta_pro_only?.value === 'false'
              ) {
                linesToAdd.push({
                  merchandiseId: currentVariant.id,
                  quantity,
                });
              }
            }
          } else {
            // Fetch product data for replacement
            const { product } = await useGetProductQuery.fetcher(
              storefrontQueryDataSource(locale),
              {
                handle: targetHandle,
                includeReferenceMetafieldDetails: false,
                ...localeCodes,
              }
            )();

            // If a suitable replacement product is found, remove the original product from the cart
            // and add the replacement to ensure it's up-to-date in the cart.
            if (product) {
              lineIdsToRemove.push(id);
              const currentVariant = product?.variants?.edges.find(
                (v) =>
                  v.node.title === line.node.merchandise.title ||
                  (v.node.sku && v.node.sku === line.node.merchandise.sku)
              )?.node;

              // eslint-disable-next-line sonarjs/no-collapsible-if
              if (
                (!pro && currentVariant?.meta_pro_only?.value === 'false') ||
                !!pro
              ) {
                if (!!currentVariant?.id) {
                  linesToAdd.push({
                    merchandiseId: currentVariant.id,
                    quantity,
                  });
                }
              }
            }
          }
        }
        // Update the cart with the modified lines
        const cartLineAddQuery = await useCartLineAddMutation.fetcher(
          mutationDataSource,
          {
            cartId,
            lineIdsToRemove,
            linesToAdd,
          }
        )();
        const cart = cartLineAddQuery.cartLinesAdd?.cart;

        // If the cart update fails, send a 'CART_ERROR' event
        if (!cart) {
          return send('CART_ERROR');
        }

        // Send a 'CART_GIFTS_CHECK' event with the modified clean cart
        return send({
          type: 'CART_GIFTS_CHECK',
          payload: { cartId, cart: cleanCart(cart) },
        });
      })
      .catch((error) => {
        console.warn(error);
        send('CART_ERROR');
      });
  };

const sendRemoveEvent = (
  line: StoreCartLines | undefined,
  quantity: number
) => {
  if (!line) return;

  const availableForSale = line.merchandise.availableForSale ?? false;
  const price = line.merchandise.amountPerQuantity ?? 0;

  const removeItemEvent = {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: line?.merchandise.price.currencyCode,
      remove: {
        products: [
          {
            name: line?.merchandise.product.title,
            id: line?.merchandise.metafields?.sku ?? '',
            price,
            brand: 'OPI Products',
            category: line?.merchandise.metafields?.systemName,
            variant: line?.merchandise.title,
            quantity: quantity,
            dimension2: line?.merchandise.metafields?.primaryColorFamily,
            dimension6: line?.merchandise.metafields?.secondaryColorFamily,
            dimension7: line?.merchandise.metafields?.colorFamilySubgroup,
            dimension8: line?.merchandise.metafields?.finish,
            dimension9: line?.merchandise.metafields?.depthOfColor,
            dimension10: line?.merchandise.metafields?.cadColorOpacity,
            dimension12: line?.merchandise.metafields?.hexColor,
            dimension13: line?.merchandise.product.title,
            dimension14: line?.merchandise.metafields?.systemName,
            dimension3: availableForSale ? 'IN STOCK' : 'OUT OF STOCK',
          },
        ],
      },
    },
  };
  sendDataToGTM(removeItemEvent);
};

const sendAddEvent = (line: StoreCartLines | undefined, quantity: number) => {
  if (!line) return;

  const availableForSale = line.merchandise.availableForSale ?? false;
  const price = line.merchandise.amountPerQuantity ?? 0;

  const gtmEvent = {
    event: 'addToCart',
    ecommerce: {
      currencyCode: line?.merchandise.price.currencyCode,
      add: {
        products: [
          {
            name: line?.merchandise.product.title,
            id: line?.merchandise.metafields?.sku ?? '',
            price,
            brand: 'OPI Products',
            category: line?.merchandise.metafields?.systemName,
            variant: line?.merchandise.title,
            quantity: quantity,
            dimension2: line?.merchandise.metafields?.primaryColorFamily,
            dimension6: line?.merchandise.metafields?.secondaryColorFamily,
            dimension7: line?.merchandise.metafields?.colorFamilySubgroup,
            dimension8: line?.merchandise.metafields?.finish,
            dimension9: line?.merchandise.metafields?.depthOfColor,
            dimension10: line?.merchandise.metafields?.cadColorOpacity,
            dimension12: line?.merchandise.metafields?.hexColor,
            dimension13: line?.merchandise.product.title,
            dimension14: line?.merchandise.metafields?.systemName,
            dimension3: availableForSale ? 'IN STOCK' : 'OUT OF STOCK',
          },
        ],
      },
    },
  };

  sendDataToGTM(gtmEvent);
};

const handleErrorWithPayload = (
  error: Record<string, string>,
  send: Sender<StoreEvents>
) => {
  console.warn(error);
  send({
    type: 'CART_ERROR',
    payload: {
      errors: [
        {
          code: '',
          message: error.message,
        },
      ],
    },
  });
};
