/* eslint-disable sonarjs/cognitive-complexity */
import type { Get } from 'type-fest';
import type { DiscountAllocation } from 'components/productContext/types/storeDiscountAllocation';
import type {
  StoreAttribute,
  StoreCart,
  StoreCartLines,
} from 'components/storeContext/storeMachine';
import type { CartFragment } from 'lib/shopify-storefront/__generated__/CartFragment';
import {
  CountryCode,
  CurrencyCode,
} from 'lib/shopify-storefront/__generated__/types';
import { getCleanProductHandle } from 'utils/helpers';

type CartLines = Get<CartFragment, 'lines.edges'>;

export const cleanCart = (cart: CartFragment): StoreCart => {
  const attributes: Array<StoreAttribute> = cleanAttributes(cart.attributes);

  const discountCodes: Array<{ code: string; applicable: boolean }> = [];
  cart.discountCodes.forEach((discount) =>
    discountCodes.push({
      code: discount.code,
      applicable: discount.applicable,
    })
  );

  const discountAllocations: Array<DiscountAllocation> =
    cart.discountAllocations?.map((allocation) => ({
      amount: Number(allocation.discountedAmount.amount),
      currencyCode: allocation.discountedAmount.amount ?? CurrencyCode['Usd'],
    }));

  const lines = cleanCartLinesEdges(cart.lines.edges);

  const cleanCart: StoreCart = {
    buyerIdentity: {
      countryCode: cart.buyerIdentity.countryCode ?? CountryCode['Us'],
      email: cart.buyerIdentity.email ?? null,
      phone: cart.buyerIdentity.phone ?? null,
      customer: null,
    },
    checkoutUrl: cart.checkoutUrl,
    lines,
    estimatedCost: {
      subtotalAmount: {
        currencyCode:
          cart.cost.subtotalAmount.currencyCode ?? CurrencyCode['Usd'],
        amount: Number(cart.cost.subtotalAmount.amount ?? 0),
      },
      totalAmount: {
        currencyCode:
          cart.cost.checkoutChargeAmount.currencyCode ?? CurrencyCode['Usd'],
        amount: Number(cart.cost.checkoutChargeAmount.amount ?? 0),
      },
    },
    discountAllocations,
    attributes,
    discountCodes,
  };

  if (cart.buyerIdentity.customer) {
    const customer = cart.buyerIdentity.customer;

    cleanCart.buyerIdentity.customer = {
      id: customer.id,
      email: customer.email ?? '',
      displayName: customer.displayName,
      firstName: customer.firstName ?? '',
      lastName: customer.lastName ?? '',
      pro: false,
      approvalPending: false,
      preferredLanguage: customer.preferredLanguage?.value ?? '',
    };
  }

  return cleanCart;
};

export const cleanAttributes = (
  attributes: Array<{
    key: string;
    value?: string | null;
  }>
): Array<StoreAttribute> => {
  const cleanAttributes: Array<StoreAttribute> = [];

  attributes.forEach((attribute) =>
    cleanAttributes.push({
      key: attribute.key,
      value: attribute.value,
    })
  );

  return cleanAttributes;
};

export const cleanCartLinesEdges = (
  lines: CartLines
): Array<StoreCartLines> => {
  const cleanLines: Array<StoreCartLines> = [];

  lines.forEach((line) => {
    const lineItem = line.node;

    const attributes: Array<StoreAttribute> = cleanAttributes(
      lineItem.attributes
    );

    const selectedOptions: Array<{ name: string; value: string }> = [];
    lineItem.merchandise.selectedOptions.forEach((option) =>
      selectedOptions.push({
        name: option.name,
        value: option.value,
      })
    );

    const discountAllocations: Array<DiscountAllocation> =
      lineItem.discountAllocations?.map((allocation) => ({
        amount: Number(allocation.discountedAmount.amount),
        currencyCode: allocation.discountedAmount.amount ?? CurrencyCode['Usd'],
      }));

    cleanLines.push({
      id: lineItem.id,
      attributes,
      discountAllocations,
      quantity: lineItem.quantity,
      merchandise: {
        id: lineItem.merchandise.id,
        availableForSale: lineItem.merchandise.availableForSale,
        requiresShipping: lineItem.merchandise.requiresShipping,
        title: lineItem.merchandise.title,
        compareAtPrice: lineItem.cost.compareAtAmountPerQuantity
          ? {
              currencyCode:
                lineItem.cost.compareAtAmountPerQuantity.currencyCode,
              amount: Number(lineItem.cost.compareAtAmountPerQuantity.amount),
            }
          : null,
        price: {
          currencyCode: lineItem.cost.totalAmount.currencyCode,
          amount: Number(lineItem.cost.totalAmount.amount),
        },
        amountPerQuantity: {
          currencyCode: lineItem.cost.amountPerQuantity.currencyCode,
          amount: Number(lineItem.cost.amountPerQuantity.amount),
        },
        image: lineItem.merchandise.image
          ? {
              id: lineItem.merchandise.image.id ?? lineItem.id,
              altText:
                lineItem.merchandise.image.id ??
                lineItem.merchandise.product.title,
              url: lineItem.merchandise.image.url,
              width: lineItem.merchandise.image.width ?? null,
              height: lineItem.merchandise.image.height ?? null,
            }
          : null,
        product: {
          handle: getCleanProductHandle(lineItem.merchandise.product.handle),
          title: lineItem.merchandise.product.title,
          productType: lineItem.merchandise.product.productType,
          tags: lineItem.merchandise.product.tags,
        },
        quantityAvailable: lineItem.merchandise.quantityAvailable ?? null,
        selectedOptions,
        sku: lineItem.merchandise.sku ?? '',
        metafields: {
          /**
           * Grab ASIN data from variant then from product if doesn't exist
           */
          asin:
            (lineItem.merchandise.meta_asin?.value ||
              lineItem.merchandise.product.meta_asin?.value) ??
            '',
          proOnly: lineItem.merchandise.meta_pro?.value ?? '',
          primaryColorFamily:
            lineItem.merchandise.product.meta_primary_color_family?.value ?? '',
          secondaryColorFamily:
            lineItem.merchandise.product.meta_secondary_color_family?.value ??
            '',
          depthOfColor:
            lineItem.merchandise.product.meta_depth_of_color?.value || '',
          finish: lineItem.merchandise.product.meta_finish?.value || '',
          hexColor: lineItem.merchandise.product.meta_hex_color?.value || '',
          cadColorOpacity:
            lineItem.merchandise.product.meta_cad_color_opacity?.value || '',
          colorFamilySubgroup:
            lineItem.merchandise.product.meta_color_family_subgroup?.value ||
            '',
          systemName:
            lineItem.merchandise.product.meta_color_collection?.value || '',
          sku: lineItem.merchandise.product.meta_sku?.value || '',
        },
      },
    });
  });

  return cleanLines;
};
