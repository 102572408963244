import { inspect } from '@xstate/inspect';
import { useState } from 'react';
import styles from './XstateDebug.module.scss';

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_XSTATE_DEBUG === 'true'
) {
  inspect();
}

export const XstateDebug = () => {
  const [hidden, setHidden] = useState(true);
  return (
    <div data-hidden={hidden} className={styles.xstateDebug}>
      <iframe
        data-xstate={true}
        className={styles.xstateDebug__iframe}
        title={'xstateDebug'}
      ></iframe>
      <button
        className={styles.xstateDebug__button}
        onClick={() => {
          setHidden(!hidden);
        }}
      >
        xstate
      </button>
    </div>
  );
};

export default XstateDebug;
