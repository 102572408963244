/**
 * Parse Handle Param
 *
 * Next query paramaters can technially be any string | string[] | undefined
 * to unify the expected behaviour this function narrows the unpredictable input
 *
 * @param handle: string | string[] | undefined
 * @returns string[]
 */
export function parseHandleParam(
  handle: string | string[] | undefined
): Array<string> {
  if (!handle) return [''];

  if (Array.isArray(handle)) return handle;

  return [handle];
}

export default parseHandleParam;
