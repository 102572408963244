'use strict';
exports.__esModule = true;
function imageLoader(_a) {
  var src = _a.src,
    width = _a.width,
    quality = _a.quality;
  if (!src) {
    return src;
  }
  var url = new URL(src);
  if (src.includes('images.ctfassets.net')) {
    url.searchParams.set('fm', 'webp');
    url.searchParams.set('w', width.toString());
    url.searchParams.set('q', (quality || 75).toString());
    return url.href;
  }
  if (src.includes('cdn.shopify.com')) {
    url.searchParams.set('width', width.toString());
    return url.href;
  }
  return url.href;
}
exports['default'] = imageLoader;
