import type { StoreLocale } from '@/root/constants';
import type { ContentfulMetadataFilter } from 'lib/contentful/__generated__/types';

export const localeToContentfulTag = new Map<StoreLocale, string>([
  ['en-GB', 'en-gb'],
  ['en-US', 'en-us'],
  ['en-CH', 'en-ch'],
  ['fr-CH', 'fr-ch'],
  ['de-CH', 'de-ch'],
  ['it-CH', 'it-ch'],
  ['fr-FR', 'fr-fr'],
  ['de-DE', 'de-de'],
  ['it-IT', 'it-it'],
  ['es-ES', 'es-es'],
  ['jp-JP', 'ja-jp'],
]);

/**
 * Generate a tag filter object for Contentful GraphQL queries.
 * - Used to limit query results to items with a certain tag applied.
 * @param locale StoreLocale
 */
export function getContentfulTagFilter(locale: StoreLocale) {
  if (!locale) return;

  const contentfulTag = localeToContentfulTag.get(locale);

  if (!contentfulTag) return;

  const tagFilter: ContentfulMetadataFilter = {
    tags: {
      id_contains_some: [contentfulTag],
    },
  };

  return {
    contentfulMetadata: tagFilter,
  };
}

export function getAlgoliaContentfulFilters(locale: StoreLocale) {
  if (!locale) return;

  const contentfulTag = localeToContentfulTag.get(locale);

  if (!contentfulTag) return;

  return `tags:${contentfulTag}`;
}

const professionalsUrlCategory = 'professionals';

export function getContentfulNailArtUrlCategoryFilter(
  professionalRoute: boolean
) {
  return professionalRoute
    ? { urlCategory: professionalsUrlCategory }
    : // eslint-disable-next-line @typescript-eslint/naming-convention
      { urlCategory_not: professionalsUrlCategory };
}

/**
 * Generate an Algolia Filter string for Nail Art content
 * - Nail Art content can be served from `/nail-art` route or `/professionals`
 *   route
 * - When `professionalsRoute` param is set to `true` - returns a filter that
 *   will return only results for the `/professionals` route, otherwise returns
 *   results that aren't the `/professionals` route (`/nail-art` route or the
 *   field is left blank)
 * - Should be set to `false` in most cases, unless there is a need to display
 *   `/professionals` content via Algolia.
 * @param professionalRoute - whether to so the Professionals content only
 * @returns Algolia filter string
 */
export function getAlgoliaNailArtUrlCategoryFilter(professionalRoute = false) {
  return professionalRoute
    ? `urlCategory:${professionalsUrlCategory}`
    : `NOT urlCategory:${professionalsUrlCategory}`;
}
