import { Trans } from '@lingui/react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import type { MouseEvent, FocusEvent } from 'react';
import { useKey } from 'rooks';
import IconChevronDown from '../../../icons/direction/chevron/down.svg';
import type {
  MediaType,
  StoreMenuItem,
  MediaBlockType,
} from '../../../types.d/storeTypes';
import styles from './DesktopMenuMain.module.scss';

/**
 * Desktop Menu Component.
 * @param props - component props.
 */
export const DesktopMenuMain = ({
  desktopMainMenu,
}: {
  desktopMainMenu: StoreMenuItem[];
}) => {
  const router = useRouter();

  const navLinkRefs = useRef<HTMLElement[]>([]);

  const closeMegaNav = () => {
    if (!navLinkRefs.current.length) return;

    navLinkRefs.current.forEach((link) => link.classList.remove(styles.hover));
  };

  useKey(['Escape'], () => closeMegaNav());

  /**
   * Close mega nav on successful route change.
   */
  useEffect(() => {
    const handleRouteChange = () => closeMegaNav();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  if (!desktopMainMenu.length) return null;

  /**
   * Handle meganav link enter leave.
   * @param event - Enter Leave Event
   */
  const handleMouseEnter = (event: MouseEvent) => {
    if (!event.currentTarget) return;

    const target = event.currentTarget as HTMLElement;
    target.classList.add(styles.hover);
  };

  /**
   * Handle meganav link mouse leave.
   * @param event - Mouse Leave Event
   */
  const handleMouseLeave = (event: MouseEvent) => {
    if (!event.currentTarget) return;

    const target = event.currentTarget as HTMLElement;
    target.classList.remove(styles.hover);
  };

  /**
   * Handle meganav link focus.
   * @param event - Focus Event
   */
  const handleLinkFocus = (event: FocusEvent) => {
    if (!event.currentTarget) return;

    const target = event.currentTarget as HTMLElement;

    if (target.classList.contains(styles.hover)) return;

    closeMegaNav();
  };

  return (
    <nav className={styles.desktopMenuMain}>
      <ul className={styles.desktopMenuMain__navigationList}>
        {desktopMainMenu.map((item: StoreMenuItem, index) => {
          return (
            <li
              className={styles.desktopMenuMain__navigationItem}
              key={item.title}
              ref={(element) =>
                element && (navLinkRefs.current[index] = element)
              }
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onFocus={handleLinkFocus}
              data-test-id="desktopMenuMainNavigationItem"
            >
              <div className={styles.desktopMenuMain__navigationLinkContainer}>
                <Link
                  href={item.url ?? ''}
                  className={[
                    styles.desktopMenuMain__navigationLink,
                    'text-utility-utility',
                  ].join(' ')}
                  target={item.newTab ? '_blank' : undefined}
                >
                  {item.title}
                </Link>

                <MeganavToggle
                  menu={item}
                  index={index}
                  parent={navLinkRefs.current[index]}
                />
              </div>

              <Meganav menu={item} index={index} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

/**
 * Meganav Toggle Component.
 * @param props - component props.
 */
const MeganavToggle = ({
  menu,
  index,
  parent,
}: {
  menu: StoreMenuItem;
  index: number;
  parent: HTMLElement;
}) => {
  if (!menu.menu?.length && !menu.medias?.images.length) return null;

  const clickHandler = () => {
    parent.classList.toggle(styles.hover);
  };

  return (
    <button
      className={styles.desktopMenuMain__navigationToggle}
      onClick={clickHandler}
      aria-controls={`meganav-${index}`}
      aria-expanded={parent?.classList.contains(styles.hover) ?? false}
    >
      <span className="visually-hidden">
        <Trans id="header.meganav.toggle" message="Show submenu for" />
        {menu.title}
      </span>
      <IconChevronDown />
    </button>
  );
};

/**
 * Meganav Component.
 * @param props - component props.
 */
const Meganav = ({ menu, index }: { menu: StoreMenuItem; index: number }) => {
  if (!menu.menu?.length && !menu.medias?.images.length) return null;

  return (
    <div
      id={`meganav-${index}`}
      className={styles.meganav}
      data-test-id="Meganav"
    >
      <div
        className={clsx(
          styles.meganav__Menus,
          `menu-item__${menu.title.toLocaleLowerCase()}`
        )}
      >
        {menu.menu && menu.menu.length > 0 && (
          <div className={styles.meganav__Lists} data-test>
            {menu.menu.map((item: StoreMenuItem) => (
              <MeganavList key={item.id} item={item} />
            ))}
          </div>
        )}

        {menu.medias && <MeganavMediaBlock medias={menu.medias} />}
      </div>
    </div>
  );
};

/**
 * Meganav List Component.
 * @param props - component props.
 */
const MeganavList = ({ item }: { item: StoreMenuItem }) => {
  return (
    <div>
      <h2
        className={clsx(
          styles.meganav__MenuTitle,
          styles.desktopMenuMain__navigationHeader,
          'text-utility-utility-small'
        )}
      >
        {item.title}
      </h2>
      <ul className={styles.meganav__MenuList}>
        {item.menu &&
          item.menu.map((itemMenu: StoreMenuItem) => (
            <li className={styles.meganav__MenuItem} key={itemMenu.id}>
              <Link
                href={itemMenu.url || ''}
                className={clsx(
                  styles.meganav__MenuLink,
                  'text-body-2-bold-mobile'
                )}
                target={itemMenu.newTab ? '_blank' : undefined}
              >
                <>
                  {itemMenu.image && (
                    <Image
                      src={itemMenu.image.url}
                      alt={itemMenu.image.title}
                      title={itemMenu.image.title}
                      width="20"
                      height="20"
                    />
                  )}
                  <span>{itemMenu.title}</span>
                </>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

/**
 * Meganav Media Block Component.
 * @param props - component props.
 */
const MeganavMediaBlock = ({ medias }: { medias: MediaBlockType }) => {
  return (
    <div>
      {medias.title && (
        <h2
          className={clsx(
            styles.meganav__BlockHeading,
            'text-utility-utility-small'
          )}
        >
          {medias.title}
        </h2>
      )}

      <div
        className={clsx(
          styles.meganav__BlockBody,
          !medias.title && styles.meganav__BlockBodyMargin
        )}
      >
        {medias.images.map((data: MediaType, index: number) => (
          <Link
            href={data.url}
            key={index}
            target={data.newTab ? '_blank' : undefined}
            className={styles.meganav__BlockColumn}
          >
            <>
              <Image
                className={styles.meganav__menuImage}
                src={data.image.url}
                alt={data.image.title}
                title={data.image.title}
                loading="lazy"
                width={222}
                height={222}
              />
              <p className="text-body-2-bold">{data.title}</p>
            </>
          </Link>
        ))}
      </div>
    </div>
  );
};
