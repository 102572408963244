import type { NextRouter } from 'next/router';
/**
 * Extracts and sanitizes the path segment from a given `asPath` property of NextRouter.
 *
 * Function performs two main operations:
 * 1. Extracts the path segment from the beginning of the input string until
 *    one of the characters `#`, `?`, or any whitespace is encountered.
 * 2. Removes any occurrences of the substring `_pro` from the extracted path.
 *
 * @example
 * // Assuming router is an instance of NextRouter and has asPath of '/example_path_pro#section1'
 * getSanitizedPath(router.asPath) // returns '/example_path'
 *
 * // Assuming router is an instance of NextRouter and has asPath of '/test/query?param=value'
 * getSanitizedPath(router.asPath) // returns '/test/query'
 *
 * // Assuming router is an instance of NextRouter and has asPath of '/test_path_pro'
 * getSanitizedPath(router.asPath) // returns '/test_path_pro'
 *
 * @param {NextRouter['asPath']} asPath - The `asPath` property from an instance of NextRouter.
 * @returns {string} - The sanitized path segment.
 */

export const getSanitizedPath = (asPath: NextRouter['asPath']): string => {
  const pathRegex = /^[^#?\s]+/;
  const path =
    pathRegex.exec(asPath)?.[0].toLowerCase() || asPath.toLowerCase();

  return path.replace('_pro', '');
};
